import React from "react";
import Sidebar from "../../../common/Sidebar/Sidebar";
import { Container, Image, Col, Form, Button } from "react-bootstrap";
import "./Setting.scss";
import ForgotPassword from "../../../common/ForgotPassword/ForgotPassword";

const Setting = () => {
  return (
    <div>
      <Container fluid className="settingPage">
        <ForgotPassword title="CHANGE PASSWORD"/>
      </Container>
    </div>
  );
};

export default Setting;
