import React, { useEffect, useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import "./Faq.scss";
import { AdminActions } from "../../../../redux/actions/admin.action";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";

const Faq = () => {
  const [faqList, setFaqList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [page, setPage] = useState(0);
  const limit = 4;
  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit: limit,
      page: e - 1,
    };
    fetchFaq(obj);
  };
  const dispatch = useDispatch();

  const fetchFaq = async (data) => {
    const { fetchFaq } = AdminActions;
    try {
      const res = await dispatch(fetchFaq(data));
      setFaqList(res.data.data.list);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {
      // console.log(error);
    }
  };
  function createMarkup(data) {
    return { __html: data.answer };
  }
  useEffect(() => {
    fetchFaq({ limit: limit, page: page });
  }, []);
  return (
    <div className="CollapseBg">
      <Container>
        <h2 className="mb-4 maintitle">Frequently Asked Questions</h2>
        <Accordion className="mb-4" >
          {faqList.length > 0 &&
            faqList.map((data, i) => (
              <Accordion.Item eventKey={i}>
                <Accordion.Header>{data.question}</Accordion.Header>
                <Accordion.Body dangerouslySetInnerHTML={createMarkup(data)} />
              </Accordion.Item>
            ))}
        </Accordion>
        {totalCount > limit && (
          <div className="layoutStyle fQ">
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Container>
    </div>
  );
};

export default Faq;
