import {LoadingActions} from "./loading.action"
import {ApiService} from "../../services/api.service";

/**
* @function getTermsCondition
* @returns terms and conditions
*/
export const getTermsCondition = () => async (dispatch) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    await dispatch(startLoader());
    let res = await ApiService.getTermsCondition();
    await dispatch(stopLoader());
    return res;
  } catch (error) {
    await dispatch(stopLoader());
    throw error;
  }
};

