import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../header/Header";
import { Footer } from "..";
import "./LayoutStyle.scss";
import { useLocation } from "react-router-dom";
import CustomizedLayout from "../../../HOC/customizedLayout";
import { useSelector } from "react-redux";

const Layout = (props) => {
  const location = useLocation();
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  const isAuth = () => {
    if (
      (location.pathname.indexOf("auth") != -1) ||
      (location.pathname.indexOf("login") != -1) || 
      (location.pathname.indexOf("signup") != -1)

    ) {
      setShowHeaderFooter(true);
    } else {
      setShowHeaderFooter(false);
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    isAuth();
  }, [location]);

  return (
    <>
      {showHeaderFooter ? (
        <>
          <Header />
          <Container
            fluid
            className={`layoutStyle ${props.className}`}
            style={props.style}
          >
            {props.children}
          </Container>
          <Footer />
        </>
      ) : (
        <Container
          fluid
          className={`layoutStyle ${props.className}`}
          style={props.style}
        >
          {props.children}
        </Container>
      )}
    </>
  );
};
const StyledLayout = CustomizedLayout(Layout);
export { StyledLayout, Layout };
