import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./media.scss";
import vector from "../../../assets/images/Vector.svg";
import twitter from "../../../assets/images/Tweeter.svg";
import Medium from "../../../assets/images/MEDIUM.svg";
import instagram from "../../../assets/images/Instagram.svg";

const MediaIcon = () => {
  return (
    <ul className="media d-flex mb-0 ps-0">
      <li>
        <a href="https://discord.gg/DV3Du9MSkb" target="_blank">
          <Image src={vector} alt="icon" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/spozzclubnft" target="_blank">
          <Image src={twitter} alt="icon" />
        </a>
      </li>
      <li>
        <a href="https://medium.com/@spozzclub" target="_blank">
          <Image src={Medium} alt="icon" />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/spozz.club/" target="_blank">
          <Image src={instagram} alt="icon" />
        </a>
      </li>
    </ul>
  );
};

export default MediaIcon;
