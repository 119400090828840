import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { toasts as toast } from "../Toast/Toast";
import "./InvitationList.css";
import Pagination from "react-js-pagination";

function InvitationList({
  deleteInvitation,
  invitationData,
  collectionDetails,
  getInvitations,
}) {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState();
  const [index, setIndex] = useState(null);
  const [page, setPage] = useState(0);
  const limit = 10;

  const createReferralObj = (invite) => {
    return {
      network: collectionDetails.blockChain?.blockChain.toLowerCase(),
      externalLink: collectionDetails.externalLink,
      inviteCode: invite.inviteCode,
    };
  };

  const handleChange = (e) => {
    setEditValue(e.target.value);
  };

  const updateMaxPerWallet = async (data) => {
    const { updateMaxPerWallet } = CollectionActions;
    if (data.body < 1) {
      toast.error("Seats can't be less then 1");
      return;
    }
    try {
      let res = await dispatch(updateMaxPerWallet(data));
      if (res) {
        getInvitations({ page, limit });
        setEdit(false);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const invitationList = invitationData.list.map((invite) => {
    return {
      ...invite,
      refObj: createReferralObj(invite),
    };
  });

  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit: limit,
      page: e - 1,
      filters: {},
    };

    getInvitations(obj);
  };

  return (
    <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>S.no</th>
          <th>Collection Name</th>
          <th>Seats</th>
          <th>Used</th>
          <th>Link</th>
          <th>Type</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {invitationList && invitationList.length > 0 ? (
          <>
            {invitationList.map((invite, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{collectionDetails.name}</td>
                {edit && i == index ? (
                  <td>
                    <input
                      type="number"
                      min="1"
                      value={editValue}
                      onChange={handleChange}
                    ></input>
                  </td>
                ) : (
                  <td>{invite.maxNumberOfWallet}</td>
                )}

                <td>{invite.numberOfWalletJoined}</td>
                <td className="invitationCode">
                  <CopyToClipboard
                    refObj={invite.refObj}
                    // style={getCustomizedColor("text")}
                  />
                </td>
                <td>{invite.type}</td>
                <td>
                  {edit ? (
                    <Button className="cmnBtn"
                      onClick={() => {
                        updateMaxPerWallet({ id: invite._id, body: editValue });
                      }}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button className="cmnBtn"
                      onClick={() => {
                        setEditValue(`${invite.maxNumberOfWallet}`);
                        setEdit(true);
                        setIndex(i);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </td>
                <td>
                  <Button className="cmnBtn" onClick={() => deleteInvitation(invite._id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={8}> No invitations found </td>
          </tr>
        )}
      </tbody>
    
    </Table>
    <div className="layoutStyle">
        {invitationData?.totalCounts > limit && (
        <Pagination
          activePage={page}
          itemsCountPerPage={limit}
          totalItemsCount={invitationData.totalCounts}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
        />
      )}
    </div>
    </>
  );
}

export default InvitationList;
