import {UrlValidator} from "./urlValidator";

class TwitterValidator extends UrlValidator {
    constructor(url){
        super(url);
       this.validateTwitterUrl()
        
    }

    validateTwitterUrl(){
        let regex = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
        const str = this.url
        this.errors.twitter = regex.test(str) ? "" : "Invalid twitter url";
    }
}

export {TwitterValidator}