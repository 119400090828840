import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import "./FooterStyle.scss";
import { NavLink } from "react-router-dom";
import MediaIcon from "../socialmedia/MediaIcon";
import logo from "../../../assets/images/LOGO.svg";

const Footer = (props) => {
  return (
    <>
      {/* <Container fluid className="footer_nav_main" style={!!props.style ? {background:props.style.footerColor}: null}>
        <div className={`footer_nav ${!!props.style ? 'customize':null}`}>
       
            <Image
              className="Logo"
              src={require("../../../assets/images/footerLogo.svg").default}
            />
            <div className="mediaIcon mt-3">
              <MediaIcon />
            </div>

            <p style={!!props.style ? {color:props.style.textColor}: null}>
              Copyright C 2022 SPOT.TradersClub. Todos los derechos reservados
            </p>
        </div>
      </Container> */}
      <footer
        style={!!props.style ? { background: props.style.footerColor } : null}
      >
        <div
          className={`navSection footer_nav ${
            !!props.style ? "customize" : null
          }`}
        >
          <ul className=" d-flex align-items-center">
            <li style={!!props.style ? { color: props.style.textColor } : null}>
              <img src={logo} alt="logo" />
            </li>
            <li style={!!props.style ? { color: props.style.textColor } : null}>
              <a href="https://info.spozz.club" target="_blank">
                About Us
              </a>
            </li>
            <li style={!!props.style ? { color: props.style.textColor } : null}>
              <a href="https://help.spozz.club" target="_blank">
                Help Center
              </a>
            </li>
            <li style={!!props.style ? { color: props.style.textColor } : null}>
              <a href="https://blog.spozz.club" target="_blank">
                SPOZZ Blog
              </a>
            </li>
          </ul>
        </div>
        <hr style={!!props.style ? { color: props.style.textColor } : null} />
        <div className="lowerSec">
          <p style={!!props.style ? { color: props.style.textColor } : null}>
            © SPOZZ.club. All rights reserved. By NFT people, for NFT people.
          </p>
          <div>
            <MediaIcon />
          </div>
        </div>
      </footer>
    </>
  );
};

export { Footer };
