import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTermsCondition } from "../../../redux/actions/terms.action";
import './TermsAndConditionsModal.scss'

function TermsAndConditionsModal({show, history, setShowTermsModal}) {
  const dispatch = useDispatch();
  const [terms, setTerms] = useState(null);

  const getTerms = async () => {
    try {
      let res = await dispatch(getTermsCondition());
      if (res.data.data && Object.keys(res.data.data).length != 0) {
        setTerms(res.data.data.text);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <Modal
      scrollable={true}
      className="connect_wallet ss termsModal"
      show={show}
      size="lg"
      centered
      onHide={()=>setShowTermsModal(false)}
    >
      <Modal.Header closeButton>Terms and Conditions</Modal.Header>
      <Modal.Body className="termsModalBody">
        <Row>
          <Col className="baseToken_style">
            <div className="text-center">
              {terms && terms != "<p><br></p>" ? (
                <div style={{ marginTop: "20px" }}>
                  <div dangerouslySetInnerHTML={{ __html: `${terms}` }}></div>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowTermsModal(false)}>
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default withRouter(TermsAndConditionsModal);
