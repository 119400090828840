import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Field } from "redux-form";
import { required, email } from "redux-form-validators";
import { UserActions } from "../../../../redux/actions/user.action";
import { MainCard, Layout } from "../../../common";
// import Email from "../../../../assets/images/icon/email.svg";
// import Phone from "../../../../assets/images/phone.svg";
// import User from "../../../../assets/images/icon/user.svg";
import ConnectWalletModal from "../../../common/ConnectWalletModal/ConnectWalletModal";
import { FormField } from "../../../common/FormField";
import { toasts as toast } from "../../../common/Toast/Toast";
import "./Login.scss";
import { withRouter } from "react-router";
import { reduxFormHelper } from "../../../../HOC/redux.form.helper";
import { PersistActions } from "../../../../redux/actions/persist.action";
import Faq from "../faq/Faq";
import AgreeTerms from "../../../common/AgreeTerms/AgreeTerms";
import ConnectWallet from "../../../common/ConnectWallet/ConnectWallet";

function Login(props) {
  const { handleSubmit, errors } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const [isTermsChecked, setisTermsChecked] = useState(false);

  const handleTerms = (value) => {
    setisTermsChecked(value)
  }
  const submitForm = async (values) => {
    try {
      const { login } = UserActions;
      const { email, password } = values;
      if(!isTermsChecked){
        throw new Error("Please agree to terms and conditions")
      }
      let data = {
        email,
        password,
      };
      await dispatch(login(data));
    } catch (error) {
      // console.log(error);
      if(error?.message){
        toast.error(error.message)
      } else {
        toast.error(error)
      }
    }
  };

  const forgotPassword = async () => {
    try {
      if (errors && errors.email) {
        props.touch("email");
      } else {
        const { email } = props;
        let data = { email };
        let res = await dispatch(UserActions.forgotPassword(data));
        toast.success(
          "Password reset email has been sent. Please check your email"
        );
        // props.history.push("/forgot-password");
      }
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <Layout>
      <div className="userLogPage">
        <section className="loginSec cmnSec">
          <div className="topSec">
            <Link to="/login" className="logBtn cmnBtn active">
              LOGIN
            </Link>
            <Link to="/signup" className="sgnBtn cmnBtn ">
              REGISTER
            </Link>
          </div>
          <div className="logForm">
            <MainCard>
              <Form
                className="logForm"
                autoComplete="off"
                autoFocus="off"
                onSubmit={handleSubmit(submitForm)}
                noValidate
              >
                <Form.Group className="fieldWrap">
                  <Field
                    component={FormField}
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    validate={[required(), email()]}
                  />
                  <div className="formIcon">
                    {/* <img src={Email} alt="" /> */}
                  </div>
                </Form.Group>


                <Form.Group className="fieldWrap">
                  <Field
                    component={FormField}
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    validate={[required()]}
                  />
                  <Form.Text id="passwordHelp" onClick={forgotPassword}>
                    Forgot your Password ?
                  </Form.Text>
                </Form.Group>

                <Form.Group className="fieldWrap">
                    <AgreeTerms handleTerms={handleTerms}/>
                </Form.Group>
                <Button className="cmnBtn2  cmnBtn" type="submit">
                  Login
                </Button>
              </Form>
             
            </MainCard>
            
          </div>
        </section>
      </div>
      {/* <div>
      <Faq/>
      </div> */}
    </Layout>
  );
}

const fields = ["email", "password"];

let formComponent = reduxFormHelper({
  form: "LoginForm",
  fields,
  component: Login,
});

export default withRouter(formComponent);
