import React from "react";
import Sidebar from "../../../common/Sidebar/Sidebar";
import "./Mainsetting.scss";
import Setting from "../Setting/Setting";
import { Layout } from "../../../common";

const Mainsetting = () => {
  return (
    <Layout>
    <div className="PrivateArea__content">
      <div className="PrivateArea__contentIn">
        <div className="dashLeft">
          <Sidebar />
        </div>
        <div className="dashRight">
          <Setting />
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default Mainsetting;
