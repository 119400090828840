import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../../../redux/actions/collection.action";
import walletService from "../../../services/wallet.service";
import { EncryptionHelper } from "../../../utils/EncryptionHelper";
import ConnectWalletModal from "../ConnectWalletModal/ConnectWalletModal";

function ConnectWallet({ isChange, className, style, collectionDetails }) {
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const dispatch = useDispatch();

  const handleWalletSelected = async (wallet) => {
    let res1 = await dispatch(
      CollectionActions.getInfuraForMint({
        collectionId: collectionDetails._id,
      })
    );
    console.log("infura id", res1.data.data.infura);
    let decryptObj = JSON.parse(
      EncryptionHelper.decrypt(res1.data.data.infura)
    );
    Object.keys(decryptObj).forEach((key) => {
      decryptObj[key] = JSON.parse(EncryptionHelper.decrypt(decryptObj[key]));
    });
    const infuraId = decryptObj.REACT_APP_INFURA_ID;
    const { saveCollectionWalletAddress, saveWalletType } = CollectionActions;

    if (wallet) {
      const account = await walletService.connectWallet(wallet, { dispatch }, infuraId);
      console.log({ account });
      dispatch(
        saveCollectionWalletAddress({
          walletAddress: account,
        })
      );
      dispatch(saveWalletType({ walletType: wallet }));
    } else {
      dispatch(saveCollectionWalletAddress({ walletAddress: "" }));
      dispatch(saveWalletType({ walletType: "" }));
    }
  };

  return (
    <>
      <Button
        className={`cmnBtn2  cmnBtn ${className}`}
        type="button"
        style={style}
        onClick={() => setShowConnectWallet(true)}
      >
        {isChange ? "Change" : "Connect Wallet"}
      </Button>

      <ConnectWalletModal
        show={showConnectWallet}
        handleWalletSelected={handleWalletSelected}
        handleClose={() => setShowConnectWallet(false)}
      />
    </>
  );
}

export default ConnectWallet;
