import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import "./Nft.css";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { useDispatch, useSelector } from "react-redux";

function Nft({
  nfts,
  getNfts,
  totalCount,
  history,
  collectionId,
  parent,
  getCustomizedColor,
}) {
  const limit = 10;
  const numberOfPages = Math.ceil(totalCount / limit);
  const dispatch = useDispatch();

  const selectedPage = useSelector((state) => {
    if (state.collection?.nft) {
      if (collectionId == state.collection?.nft?.collectionId) {
        return state.collection.nft.currentPage;
      }
    }
    return 0;
  });
  const [page, setPage] = useState(selectedPage || 0);

  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit: limit,
      page: e - 1,
      filters: {},
    };
    getNfts(obj);
  };

  const goToNftDetails = async (nft) => {
    if (parent === "collectionDetails") {
      history.push(`/auth/nft-details/${collectionId}/${nft._id}`);
    } else {
      history.replace(`/auth/nft-details/${collectionId}/${nft._id}`);
    }
    await dispatch(
      CollectionActions.saveNftPage({
        collectionId,
        currentPage: page,
      })
    );
  };

  return (
    <Container className="ContMain mb-5">
      {nfts && nfts.length > 0 ? (
        <>
          <Row className="banner_row mb-4">
            <h3
              className="title"
              style={getCustomizedColor ? getCustomizedColor("heading") : null}
            >
              NFTs Collection
            </h3>
            {nfts.map((nft, i) => (
              <Col sm={6} lg={3} style={{ cursor: "pointer" }} key={i}>
                <div
                  className="coll-new mb-4"
                  onClick={() => goToNftDetails(nft)}
                >
                  <img
                    style={{
                      // maxWidth: "395px",
                      // height: "330px",
                      width: "100%",
                    }}
                    src={nft.url}
                    alt="name"
                  />
                </div>
              </Col>
            ))}
          </Row>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalCount}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
            style={{ color: "red" }}
          />
        </>
      ) : (
        <p>No NFT's Found</p>
      )}
    </Container>
  );
}

export default withRouter(Nft);
