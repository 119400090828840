import React from "react";
import { useEffect } from "react";

function BannerNftCustomization({ customize, setCustomize, setIsCustomized }) {
  const handleChange = (e) => {
    const { name, checked } = e.target;
    setCustomize((x) => {
      return {
        ...x,
        images: {
          ...x.images,
          [name]: checked
            ? { ...x.images[name], isShow: true }
            : { ...x.images[name], isShow: false },
        },
      };
    });
  };

  const handleShowNft = (e) => { 
    const {checked,name} = e.target;
    setCustomize((x)=>{
      return {
        ...x,
        isShowNftList:checked
      }
    })
  }

  useEffect(() => {}, [customize]);

  return (
    <section className="third">
      <h5 className="subTitle">Banner & Collections</h5>
      <p>Select your banner and Collections. </p>
      <ul>
        <li>
          <div class="form-group">
            <input
              type="checkbox"
              id="remember_me"
              class="checkBox"
              name="banner1"
              value="1"
              onChange={handleChange}
              checked={customize.images.banner1.isShow == true}
            />
            <label for="remember_me">Banner #1</label>
          </div>
        </li>
        <li>
          <div class="form-group">
            <input
              type="checkbox"
              id="sec"
              class="checkBox"
              name="banner2"
              onChange={handleChange}
              checked={customize.images.banner2.isShow == true}
            />
            <label for="sec">Banner #2 </label>
          </div>
        </li>
        <li>
          <div class="form-group">
            <input
              type="checkbox"
              id="third"
              class="checkBox"
              name="isShowNftList"
              value="1"
              onChange={handleShowNft}
              checked={customize.isShowNftList==true}
            />
            <label for="third">NFTs Collection</label>
          </div>
        </li>
      </ul>
    </section>
  );
}

export default BannerNftCustomization;
