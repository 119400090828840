import types from "../types";

const initialState = {
  loading: false
};

const loading = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.loading.SAVE_GET_LOADING:
      return { ...state, loading: payload.loading };
    default:
      return state;
  }
};

export default loading;
