import React, { useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import "./ConnectWalletModal.scss";
import { Link } from "react-router-dom";
import { Enviroments } from "../../../constants/constants";
import { useSelector } from "react-redux";

const ModalCurrency = (props) => {
  const walletType = useSelector(
    (state) => state.collection?.collections?.walletType
  );

  const walletAddress = useSelector(
    (state) => state.collection?.collections?.walletAddress
  );

  const selectWallet = (wallet) => {
    props.handleClose();
    props.handleWalletSelected(wallet);
  };

  const disconnectWallet = () => {
    props.handleWalletSelected();
    props.handleClose();
    localStorage.removeItem("walletconnect");
  };

  return (
    <Modal
      scrollable={true}
      className="connect_wallet"
      show={props.show}
      onHide={props.handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Connect to a wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="baseToken_style">
            <ul>
              {Enviroments.WALLETS.map((wallet) => (
                <li>
                  <button
                    disabled={walletType === wallet.type}
                    onClick={() => selectWallet(wallet.type)}
                    className="walletBtns"
                  >
                    {wallet.text}
                    <span>
                      <img src={wallet.image} alt="icon" />
                    </span>
                  </button>
                </li>
              ))}
            </ul>
            {!!walletAddress && (
              <div class="add_new text-center">
                <Button onClick={disconnectWallet}>Disconnect Wallet</Button>
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCurrency;
