import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { FormField } from "../FormField";

function EditCollectionModal({
  handleSubmit,
  setShow,
  show,
  collectionDetails,
  setCollectionDetails,
  change,
  modifier
}) {
  const dispatch = useDispatch();

  const hideModal = () => {
    setShow(false);
  };

  const submitForm = (values) => {
    const data = {
      [modifier.name]: Object.values(values)[0],
    };
    updateCollection(data);
  };

  const updateCollection = async (data) => {
    try {
      await dispatch(
        CollectionActions.updateCollection(collectionDetails._id, data)
      );
      setCollectionDetails({
        ...collectionDetails,
        ...data
      });
      hideModal();
    } catch (error) {
      
    }
  };

  useEffect(() => {
    const name = modifier.name;
    change(name, collectionDetails[name]);
  }, [collectionDetails[modifier.name]]);

  return (
    <Modal
      scrollable={true}
      className="connect_wallet modalComn"
      show={show}
      size="md"
      centered
    >
      <Form onSubmit={handleSubmit(submitForm)}>
        <Modal.Header closeButton onClick={hideModal}>
          <Modal.Title>Update collection</Modal.Title>
        </Modal.Header>
        <Modal.Body className="attributesModal">
          <Row>
            <Col lg={{ size: 8 }}>
              <Row>
                <Col lg={12}>
                  <Form.Group className="" controlId="formPlaintexttext">
                    <Form.Label>{modifier.text}</Form.Label>
                    <Field
                      name={modifier.name}
                      className="form-control"
                      type="text"
                      component={FormField}
                      placeholder="e. g 10"
                      validate={modifier.validation}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary cmnBtn" type="submit">
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const EditCollectionForm = reduxForm({
  enableReinitialize:true,
  form:"editCollectionForm"
})(EditCollectionModal)

export default withRouter(EditCollectionForm);
