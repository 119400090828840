import { ApiService } from "../../services/api.service";
import { LoadingActions } from "./loading.action";

const getHeaderValues = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    const res = await ApiService.getHeaderValues(data, headers);
    dispatch(stopLoader());
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

const getTestimonials = () => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    const res = await ApiService.getTestimonials(
      { isShow: true, page: "0", limit: "5" },
      headers
    );
    dispatch(stopLoader());
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

export const HomepageActions = {
  getHeaderValues,
  getTestimonials,
};
