import React, { useEffect } from "react";
import "../login-signup/Login.scss";
import { reduxFormHelper } from "../../../../HOC/redux.form.helper";

import { withRouter } from "react-router";
import ForgotPassword from "../../../common/ForgotPassword/ForgotPassword";
import { Layout } from "../../../common";
import { useLocation } from "react-router-dom";
import { PersistActions } from "../../../../redux/actions/persist.action";
import { useDispatch } from "react-redux";

function ResetPassword() {
  const search = useLocation().search;
  const dispatch = useDispatch();

  useEffect(()=>{
    getToken()
  },[])

  const getToken = () => {
    const token = new URLSearchParams(search).get("token");
    storeToken(token)
  }

  const storeToken = async (token) => {
    try {
      await dispatch(PersistActions.saveToken(token))
    }catch(error){
      // console.log(error)
    }
  }

  return (
    <Layout>
      <ForgotPassword title="FORGOT PASSWORD" />
    </Layout>
  );
}

const fields = ["password", "new_password"];
const ForgotPassForm = reduxFormHelper({
  form: "resetPassword",
  fields,
  component: ResetPassword,
});

export default withRouter(ForgotPassForm);
