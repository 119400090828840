import axios from "axios";
import { toasts as toast } from "../components/common/Toast/Toast";
import { EncryptionHelper } from "../utils/EncryptionHelper";


export { _fetch as fetch };

function checkUnAuthenticated(status){
  if(status == 401) {
    localStorage.clear();
    window.location.reload()
    return;
  }
}

function handleError(error, reject) {
  checkUnAuthenticated(error?.status)
  error?.data?.message && toast.error(error.data.message);
  error?.response?.data?.message && toast.error(error.response.data.message);
  error?.data?.errors?.message &&
    toast.error(error?.data?.errors?.message);
  reject(error);
  return;
}

function handleResponse(successs, resolve) {
  resolve(successs);
  return;
}

function setMethod(method, path, body, headers, encrypt, params) {
  let config = {};
  
  if ((headers) || (Object.keys(headers).length > 0)) { config.headers = { ...headers }; }
  
  if (headers) {
    if (headers.jwt) {
      config.headers = {
        "api-access-token": headers?.jwt
      };
      delete config.headers.jwt
    }
  }
  params = params ? "?" + new URLSearchParams(params).toString() : "";
  if (encrypt) {
    body = EncryptionHelper.encrypt(body);
  }
  if (method === "get" || method === "delete") {
    return axios[method](`${path}${params}`, config);
  }
  if (method === "post" || method === "put") {
    return axios[method](`${path}`, body, config);
  }
}

function _fetch(method, path, body, headers, encrypt, params) {
  return new Promise((resolve, reject) => {
    return setMethod(method, path, body, headers, encrypt, params).then((response) => {
      handleResponse(response, resolve);
      return;
    }).catch((error) => {
      handleError(error.response, reject);
      return;
    });
  });
}


