import React from "react";
import { useEffect } from "react";

function MintingNote({
  getCustomizedColor,
  collectionDetails,
  availableNfts,
  isPrivate,
}) {

  useEffect(()=>{
    console.log({availableNfts});
  },[availableNfts])
  return (
    <>
      {isPrivate ? (
        <h5 style={getCustomizedColor("heading")}>
          Note:-{" "}
          <span
            style={getCustomizedColor("text")}
            className="max_wallet_per_mint"
          >
            The maximum number of {availableNfts > 1 ? "NFTs" : "NFT"} that can be minted is {availableNfts}
          </span>
        </h5>
      ) : (
        <h5 style={getCustomizedColor("heading")}>
          Note:-{" "}
          <span
            style={getCustomizedColor("text")}
            className="max_wallet_per_mint"
          >
            The maximum number of {availableNfts > 1 ? "NFTs" : "NFT"} that can be minted is {`${availableNfts} / ${collectionDetails.mintPerWallet < collectionDetails.supply ? collectionDetails.mintPerWallet : collectionDetails.supply}`}{" "}
            per wallet (user){" "}
          </span>
        </h5>
      )}
    </>
  );
}

export default MintingNote;
