import React from "react";
import { Image } from "react-bootstrap";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";

function MoreInfo({ getCustomizedColor, collectionDetails, externalLink }) {
  return (
    <div className="information moreInfo">
      <h3 style={getCustomizedColor("heading")}>More information</h3>
      <div className="SupplyMain">
        {/* <div className="Supply mb-3">
          <h4 style={getCustomizedColor("heading")}>Collection Url</h4>
          <CopyToClipboard
            text={`http://stage-launchpad.spot.trade/${collectionDetails.blockChain?.blockChain.toLowerCase()}?collection=${externalLink}&code=abc`}
            style={getCustomizedColor("text")}
          />
        </div> */}
        <div className="Supply mb-3">
          <h4 style={getCustomizedColor("heading")}>
            what is the total supply
          </h4>
          <p style={getCustomizedColor("text")}>
            {collectionDetails.supply} Unique NFT's
          </p>
        </div>
        <div className="Supply mb-3">
          {" "}
          <h4 style={getCustomizedColor("heading")}> how much per NFT</h4>
          <p style={getCustomizedColor("text")}>
            {collectionDetails.blockChain && (
              <Image
                width={35}
                height={35}
                className="me-2"
                src={
                  require(`../../../assets/images/${collectionDetails.blockChain.blockChain}.png`)
                    .default
                }
              />
            )}
            {collectionDetails.price}
          </p>
        </div>
        <div className="Supply mb-3">
          {" "}
          <h4 style={getCustomizedColor("heading")}>MAXIMUM # per mints</h4>
          <p style={getCustomizedColor("text")}>
            {collectionDetails.mintPerWallet} Unique NFT's
          </p>
        </div>
      </div>
    </div>
  );
}

export default MoreInfo;
