import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AuthGuard = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = rest;
  // alert("hi from guard");
  return (
    
    <Route
      {...rest}
      render={props =>{
        if(isLoggedIn){
          return <Component {...props} />
        } else {
        
            return <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location
              }
            }}
          />
        
        }
      }
       
        
        
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    isLoggedIn: (state && state.persist && state.persist.isLoggedIn) || false
  };
};

export default connect(
  mapStateToProps,
  null
)(AuthGuard);
