import {UrlValidator} from "./urlValidator";

class FacebookValidator extends UrlValidator{
    constructor(url){
        super(url);
       this.validateFacebookUrl()
    }
    validateFacebookUrl(){
        let regex = /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/;
        const str = this.url
        this.errors.facebook = regex.test(str) ? "" : "Invalid facebook url";
    }
}

export {FacebookValidator}