/* eslint-disable no-useless-constructor */
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Enviroments } from "../constants/constants";
import { NetworkHelper } from "../utils/networkHelper";
import Web3Service from "./web3.service";
import { CollectionActions } from "../redux/actions/collection.action";

class WalletConnect {
  web3 = {};
  provider = {};

  constructor() {}

  intitalizeProvider = (chainId, infuraId) => {
    return new WalletConnectProvider({
      infuraId: infuraId, // Required
      rpc: Enviroments.WC_RPC,
      qrcode: true,
      chainId: !chainId ? 4 : chainId,
      qrcodeModalOptions: Enviroments.QR_CODE_LINKS,
    });
  };

  async enableWallet(redux, chainId, infuraId) {
    try {
      this.provider = this.intitalizeProvider(chainId, infuraId);
      this.web3 = new Web3(this.provider);
      this.subscribeAccountChanges(redux);
      const address = await this.provider.enable();
      return { address, provider: this.provider };
    } catch (error) {
      throw error;
    }
  }
  // {"persist":"{\"walletAddress\":\"\",\"isLoggedIn\":false,\"jwtToken\":\"\",\"user\":null}","collection":"{\"collections\":{\"walletAddress\":\"0x661597eb8f4c5c1efdf4aeab1780d18c676d1b7f\"},\"isNFTCreationAllowed\":false,\"currentCollection\":{\"nftUploads\":0,\"collectionId\":\"\"},\"nft\":{\"collectionId\":\"\",\"currentPage\":0}}","_persist":"{\"version\":-1,\"rehydrated\":true}"}

  // Subscribe to accounts change
  subscribeAccountChanges = (redux) => {
    this.provider.on("accountsChanged", (accounts) => {
      if (redux && redux.dispatch) {
        const { dispatch } = redux;
        dispatch(
          CollectionActions.saveCollectionWalletAddress({
            walletAddress: accounts[0],
          })
        );
        dispatch(
          CollectionActions.saveWalletType({ walletType: "WALLETCONNECT" })
        );
      }
    });
  };

  // Subscribe to chainId change
  subscribeChainIdChanges = async () => {
    return new Promise((resolve, reject) => {
      this.provider.on("chainChanged", (chainId) => {
        console.log(chainId);
        resolve(chainId);
      });
    });
  };

  connectWalletConnect = async () => {
    return await this.initialize();
  };
}

export default new WalletConnect();
