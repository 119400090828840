import React from "react";
import "./HeaderStyle.scss";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import Logo from "../../../assets/images/LOGO.svg";
import MediaIcon from "../socialmedia/MediaIcon";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { GearFill } from "react-bootstrap-icons";

const Header = (props) => {
  const dispatch = useDispatch();
  const history = props.history;

  const logout = async () => {
    await dispatch({
      type: "LOGOUT_USERS_PERSIST",
    });
  };

  const goToCollection = () => {
    history.push('/auth/collection/allCollection');
  }

  const navigate = (url) => {
    history.push(url);
  };

  return (
    <div
      className="header"
      style={!!props.style ? { background: props.style.headerColor } : null}
    >
      <Navbar expand="md">
        <Navbar.Brand style={{ cursor: "pointer" }}>
          <NavLink to="/">
            <img src={Logo} alt={"logo-icon"} />
          </NavLink>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav " />
        <Navbar.Collapse id="basic-navbar-nav" className="ml-auto">
          {!props.isLoggedIn ? (
            <Nav className="">
              <button
                style={
                  !!props.style
                    ? {
                        background: props.style.buttonBackground,
                        color: props.style.buttonColor,
                      }
                    : null
                }
                onClick={() => navigate("/login")}
                className=" loginBtn cmnBtn "
              >
                LOGIN
              </button>
              <button
                style={
                  !!props.style ? { background: props.style.buttonColor } : null
                }
                onClick={() => navigate("/signup")}
                className=" signBtn cmnBtn "
              >
                REGISTER
              </button>
            </Nav>
          ) : (
            <Nav className="">
              <GearFill
                color="#45b3d2"
                size={40}
                className="gearicon me-3"
                onClick={() => navigate("/auth/mainsetting")}
              />
              <button
                onClick={() => goToCollection()}
                style={
                  !!props.style
                    ? {
                        background: props.style.buttonBackground,
                        color: props.style.buttonColor,
                      }
                    : null
                }
                className=" loginBtn cmnBtn me-3"
              >
                My collections
              </button>
              <button
                onClick={() => logout()}
                style={
                  !!props.style
                    ? {
                        background: props.style.buttonBackground,
                        color: props.style.buttonColor,
                      }
                    : null
                }
                className=" loginBtn cmnBtn me-3"
              >
                LOGOUT
              </button>

              
            </Nav>
          )}

          <MediaIcon />
        </Navbar.Collapse>
        {/* <MediaIcon /> */}
      </Navbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.persist.isLoggedIn,
});

export default withRouter(connect(mapStateToProps)(Header));
