import React, { useState } from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";

function DeleteConfirmationModal(props) {
  return (
    <Modal
      scrollable={true}
      className="connect_wallet"
      show={props.show}
      onHide={props.handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete {props.item}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="baseToken_style">
            <label>{props.message} </label>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cmnBtn" onClick={() => props.confirmDelete(true)}>
          Yes
        </Button>
        <Button className="cmnBtn" onClick={() => props.confirmDelete(false)}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmationModal;
