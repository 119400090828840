import React, { useState } from "react";
import "./Slider.scss";
import astronote from "../../../assets/images/Astronote.svg";
import { useDispatch } from "react-redux";
import { HomepageActions } from "../../../redux/actions/homepage.action";
import { Image, Carousel } from "react-bootstrap";

function Slider() {
  const [list, setList] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    listRecords();
  }, []);

  const listRecords = async () => {
    const { getTestimonials } = HomepageActions;
    const record = await dispatch(getTestimonials());
    console.log(record?.data, "~~~~~~~~~~~`");
    setList(record?.data?.data[0].list);
  };
  console.log({ list });
  return (
    <>
      <div className="slider-sec ">
        <Carousel fade>
          {list.map((data) => (
            <Carousel.Item>
              <div className="imgWrap">
                <Image
                  className="d-block astro"
                  src={data?.image}
                  alt="First slide"
                />
              </div>
              <Carousel.Caption>
                <div className="discription">
                 {data?.fullName}
                </div>
                <p className="concoin ">{data?.content}</p>
                {/* <a href=""> https://scomyou.io</a> */}
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Slider;
