import types from "../types";

const startLoader = () => ({
  type: types.loading.SAVE_GET_LOADING,
  payload: { loading: true },
});

const stopLoader = () => ({
  type: types.loading.SAVE_GET_LOADING,
  payload: { loading: false },
});


export const LoadingActions = {
  startLoader,
  stopLoader
 
};
