import React from "react";
import { Button } from "react-bootstrap";

function CreatorButtons({
    getCustomizedColor,
    handleShow,
    collectionDetails,
    contractFetch,
    setShowUpdateDatesModal,
    history
}) {
  return (
    <>
      <Button
        variant="primary"
        style={getCustomizedColor("button")}
        className="cmnBtn mb-4"
        onClick={handleShow}
      >
        Customise
      </Button>
      {collectionDetails.isPrivateMintingAllowed && !!contractFetch.contract && (
        <Button
        variant="primary"
        style={getCustomizedColor("button")}
        className="cmnBtn mb-4 ms-3"
        onClick={() =>
          history.push({
            pathname: `/auth/private-nfts/${collectionDetails._id}`,
            state: {
              collectionDetails,
              contract: contractFetch.contract,
            },
          })
        }
      >
        See Private minted nfts
      </Button>
      )}
      
      <Button
        variant="primary"
        style={getCustomizedColor("button")}
        className="cmnBtn mb-4 ms-3"
        onClick={() => setShowUpdateDatesModal(true)}
      >
        Extend minting times
      </Button>
    </>
  );
}

export default CreatorButtons;
