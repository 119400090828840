import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CollectionActions } from "../../../redux/actions/collection.action";
import FileService from "../../../services/fileValidation.service";

function AddToWhitelist(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch() 

  const closeModal = () => {
    props.setShowWhitelistModal(false);
  };

  const acceptedFileTypes = ["text/csv"];

  const selectFile = (e) => {
    const file = e.target.files[0];
    let fileTypeAccepted = FileService.validate(file, acceptedFileTypes);
    if (fileTypeAccepted) {
      setSelectedFile(file)
    } else {
      setSelectedFile(null)
    }
  };

  const uploadFile = async () => {
    try {
      if(!selectedFile) {
        throw new Error("Please select csv file")
      }
      let formData = new FormData();
      formData.append("file",selectedFile);
      formData.append("collectionId",props.collectionId)
      await dispatch(CollectionActions.uploadWhitelistUsersCsv(formData))
      closeModal()
    } catch(error){
      toast.error(error.message)
    }
    
    
  };

  return (
    <Modal
      scrollable={true}
      className="connect_wallet ss"
      show={props.show}
      size="lg"
      centered
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload csv</Modal.Title>
      </Modal.Header>
      <Modal.Body className="accessModal">
        <Row>
          <Col className="baseToken_style">
            <p style={{ fontSize: "22px" }}>
              Please select csv file to whitelist users
            </p>
            <input
              type="file"
              name=""
              id=""
              accept={acceptedFileTypes.join(",")}
              onChange={selectFile}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={uploadFile}>
          Upload
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddToWhitelist;
