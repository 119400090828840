import { FacebookValidator } from "./facebookValidator";
import { TwitterValidator } from "./twitterValidator";
import { InstagramValidator } from "./instagramValidator";
import {DiscordValidator} from './discordValidator';

export const validators = {
    FacebookValidator,
    TwitterValidator,
    InstagramValidator,
    DiscordValidator
}
