import { connect } from "react-redux";
import { compose } from "redux";
import { formValueSelector, getFormSyncErrors, reduxForm } from "redux-form";

/*
   function that accepts form name, fields array
   it returns the selector function
*/

const getFormValue = (form, fields) => {
  const formSelector = formValueSelector(form);
  let formObj = {
    form,
    fields,
    formSelector,
  };
  const selector = (state) =>
    getFormSelectorObj({ ...formObj, state });

  return selector;
};

/*
   function that accepts form name, fields array,state,formselector
   it returns the selector object
*/

const getFormSelectorObj = ({
  form,
  fields,
  state,
  formSelector
}) => {
  let obj = {};
  for (let field of fields) {
    obj[field] = formSelector(state, field);
    obj["errors"] = getFormSyncErrors(form)(state);
  }

  return obj;
};

/*
   function that accepts form name, fields array and component
   it returns the component
*/
export const reduxFormHelper = (obj) => {
  const { form, fields, component, validate } = obj;
  const formData = compose(
    connect(getFormValue(form, fields)),
    reduxForm({
      form: form,
      enableReinitialize: true,
      touchOnChange: true,
      validate
    })
  )(component);

  return formData;
};
