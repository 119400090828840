import * as CryptoJS from "crypto-js";
import { Enviroments } from "../constants/constants";

function requestDecryption(data) {
  if (data) {
    const key = Enviroments.ENCRYPTION_KEY;
    return CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
  }
  return "";
}

function requestEncryption(data) {
  let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), Enviroments.ENCRYPTION_KEY);
  return { data: ciphertext.toString() };
}

export const EncryptionHelper = {
    decrypt:requestDecryption,
    encrypt:requestEncryption
}
