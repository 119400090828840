import React from "react";
import { Route, Switch } from "react-router-dom";
import CollectionDetails from "../../components/pages/private/CollectionDetails/CollectionDetails";
import CreateNew from "../../components/pages/private/CreateNewItems/CreateNew";
import AllCollection from "../../components/pages/private/AllCollection/AllCollection";
import UploadNft from "../../components/pages/private/upload-nft/UploadNft";
import Mainsetting from '../../components/pages/private/Mainsetting/Mainsetting'
import NftDetails from "../../components/pages/private/NftDetails/NftDetails";
import PageNotFound from "../../components/common/PageNotFound/PageNotFound";
import PrivateNfts from "../../components/pages/private/PrivateNfts/PrivateNfts";

const PrivateRoutes = () => {
  return (
    <Switch>
      <Route path="/auth/collection/allCollection" component={AllCollection} exact />
      <Route path="/auth/collection/CreateNew" component={CreateNew} exact />
      <Route path="/auth/collection/UploadNft/:collectionId" component={UploadNft} exact />
      <Route path="/auth/collection/details/:externalLink" component={CollectionDetails} exact />
      <Route path="/auth/mainsetting" component={Mainsetting} />
      <Route path="/auth/nft-details/:collectionId/:nftId" component={NftDetails} />
      <Route path="/auth/private-nfts/:collectionId" component={PrivateNfts} />
      <Route path="*" component={PageNotFound}/>
    </Switch>
  );
};

export default PrivateRoutes;
