import React from "react";
import styles from './PageNotFound.module.css'

function PageNotFound() {
  return (
    <>
    <div className={styles.pageNotFound}>
    <div className={styles.number}>404</div>
      <div className={styles.text}>
        <span>Ooops...</span>
        <br />
        page not found
      </div>
      <a
        className={styles.me}
        href="https://codepen.io/uzcho_/pens/popular/?grid_type=list"
        target="_blank"
      ></a>
    </div>
      
    </>
  );
}

export default PageNotFound;
