import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { Enviroments } from "../../../constants/constants";
import moment from "moment";
import { toasts as toast } from "../Toast/Toast";
import PrivateMinting from "../PrivateMinting/PrivateMinting";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { useDispatch } from "react-redux";

function ReleaseDateConfirmationModal(props) {
  let { COUNTDOWN_TYPES, PUBLISH_DATES } = Enviroments;
  const times = PUBLISH_DATES;
  const res =
    props.collectionDetails && props.collectionDetails.isWhiteListedEnable
      ? "yes"
      : "no";
  const [isWhiteList, setIsWhiteList] = useState(res);
  const [countdownTimes, setCountdownTimes] = useState({ ...times });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [privateMintData, setPrivateMintdata] = useState({
    isPrivateMintingAllowed: false,
    startMint: 0,
    endMint: 0,
  });

  const dispatch = useDispatch();

  const setPublishParams = (date) => {
    for (let time in times) {
      times[time].start_time = date;
      times[time].end_time = date;
    }

    setCountdownTimes(times);
  };

  const handleWhitelistChange = (e) => {
    setIsWhiteList(e.target.value);
  };

  const setTime = (index, type, date) => {
    let item = Object.keys(countdownTimes)[index];
    let itemType = Object.keys(countdownTimes[item])[type];
    let selectedItem = countdownTimes[item][itemType];
    selectedItem = date;
    setCountdownTimes((times) => {
      return {
        ...times,
        [item]: { ...times[item], [itemType]: selectedItem },
      };
    });
  };

  const formatDate = (date) => {
    let newDate = "";
    if (date instanceof Date || !!date) {
      newDate = moment.utc(date).format();
      return newDate;
    }
    toast.error("Please select datetime");
    return null;
  };

  const validatePrivateMinting = () => {
    if (privateMintData.isPrivateMintingAllowed) {
      if (!privateMintData.startMint) {
        toast.error("Please select start mint value");
        return false;
      }
      if (!privateMintData.endMint) {
        toast.error("Please select end mint value");
        return false;
      }
    }
    return true;
  };

  const handleResponse = (choice, dates, isWhiteList) => {
    try {
      if (choice) {
        let isPrivateMintingValid = validatePrivateMinting();
        if (isPrivateMintingValid) {
          handlePreSaleAndPostSale(dates, isWhiteList);
          props.setShowReleaseDateModal(false);
        }
      } else {
        if (props.setShowUpdateDatesModal) {
          props.setShowUpdateDatesModal(false);
        }
        props.setShowReleaseDateModal(false);
      }
    } catch (error) {
      toast.error(error.message);
      props.setShowReleaseDateModal(false);
      if (props.setShowUpdateDatesModal) {
        props.setShowUpdateDatesModal(false);
      }
    }
  };

  const handlePreSaleAndPostSale = (dates, isWhiteList) => {
    try {
      const { whitelist, presale, mainsale } = dates;
      // console.clear();
      if (isWhiteList === "yes") {
        if (!props.showUpdateDatesModal) {
          // Checking with current datetime
          Object.keys(dates).forEach((date) => {
            Object.keys(dates[date]).forEach((time) => {
              const selectedTime = dates[date][time];
              if (new Date().getTime() > new Date(selectedTime).getTime()) {
                throw new Error(
                  `${date} ${time.split("_").join(" ")} can't be in past`
                );
              }
            });
          });
        }

        //Checking with each other
        let count = 0;
        let new_arr = Object.keys(dates).map((key) => {
          return Object.keys(dates[key]).map((time) => {
            count += 1;
            return {
              name: key,
              timeLabel: time,
              index: count,
              time: dates[key][time],
            };
          });
        });

        let finalArr = new_arr.flat();

        for (var i = 0; i < finalArr.length; i++) {
          for (var j = i + 1; j < finalArr.length; j++) {
            if (
              new Date(finalArr[i].time).getTime() >=
              new Date(finalArr[j].time).getTime()
            ) {
              throw new Error(
                `${finalArr[j]["name"]} ${finalArr[j]["timeLabel"]
                  .split("_")
                  .join(" ")} can't be less than ${
                  finalArr[i]["name"]
                } ${finalArr[i]["timeLabel"].split("_").join(" ")}`
              );
            }
          }
        }
        console.log({ privateMintData });

        if (!props.showUpdateDatesModal) {
          props.setLiveParams({
            releaseDate: formatDate(whitelist.start_time),
            whiteListEndTime: formatDate(whitelist.end_time),
            preSaleStartTime: formatDate(presale.start_time),
            preSaleEndTime: formatDate(presale.end_time),
            mainSaleStartsIn: formatDate(mainsale.start_time),
            mainSaleEndsIn: formatDate(mainsale.end_time),
            isWhiteListedEnable: true,
            ...privateMintData,
          });
        } else {
          let data = {
            releaseDate: formatDate(whitelist.start_time),
            whiteListEndTime: formatDate(whitelist.end_time),
            preSaleStartTime: formatDate(presale.start_time),
            preSaleEndTime: formatDate(presale.end_time),
            mainSaleStartsIn: formatDate(mainsale.start_time),
            mainSaleEndsIn: formatDate(mainsale.end_time),
            isWhiteListedEnable: true,
          };
          updateDates(data);
        }
      } else {
        // Checking with current datetime
        if (!props.showUpdateDatesModal) {
          Object.keys(dates)
            .filter((x) => x === "mainsale")
            .forEach((date) => {
              Object.keys(dates[date]).forEach((time) => {
                const selectedTime = dates[date][time];
                if (new Date().getTime() > new Date(selectedTime).getTime()) {
                  throw new Error(
                    `${date} ${time.split("_").join(" ")} can't be in past`
                  );
                }
              });
            });
        }

        //Checking with each other
        let count = 0;
        let new_arr = Object.keys(dates)
          .filter((x) => x == "mainsale")
          .map((key) => {
            return Object.keys(dates[key]).map((time) => {
              count += 1;
              return {
                name: key,
                timeLabel: time,
                index: count,
                time: dates[key][time],
              };
            });
          });

        let finalArr = new_arr.flat();

        for (var i = 0; i < finalArr.length; i++) {
          for (var j = i + 1; j < finalArr.length; j++) {
            if (
              new Date(finalArr[i].time).getTime() >=
              new Date(finalArr[j].time).getTime()
            ) {
              throw new Error(
                `${finalArr[j]["name"]} ${finalArr[j]["timeLabel"]
                  .split("_")
                  .join(" ")} can't be less than ${
                  finalArr[i]["name"]
                } ${finalArr[i]["timeLabel"].split("_").join(" ")}`
              );
            }
          }
        }
        if (!props.showUpdateDatesModal) {
          props.setLiveParams({
            releaseDate: "",
            preSaleEndTime: "",
            preSaleStartTime: "",
            mainSaleStartsIn: formatDate(mainsale.start_time),
            isWhiteListedEnable: false,
            mainSaleEndsIn: formatDate(mainsale.end_time),
            ...privateMintData,
          });
        } else {
          let data = {
            releaseDate: "",
            preSaleEndTime: "",
            preSaleStartTime: "",
            mainSaleStartsIn: formatDate(mainsale.start_time),
            isWhiteListedEnable: false,
            mainSaleEndsIn: formatDate(mainsale.end_time),
          };
          updateDates(data);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const handlePrivateMinting = (data) => {
    console.log({data});
    let obj = {
      isPrivateMintingAllowed: data.isPrivateMinting == "yes" ? true : false,
      startMint: data.range.from,
      endMint: data.range.to,
    };

    console.log({ range: obj });
    setPrivateMintdata(obj);
  };

  const updateDates = async (data) => {
    try {
      console.log({ updateData: data });
      let res = await dispatch(
        CollectionActions.updateCollection(props.collectionDetails._id, data)
      );
      props.setShowUpdateDatesModal(false);
      props.handleDatesUpdate();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {}, [countdownTimes]);

  useEffect(() => {
    if (props.show) {
      setPublishParams(currentDate);
    }
  }, [props.show]);

  return (
    <Modal
      scrollable={true}
      className="connect_wallet modalComn"
      show={props.show}
      onHide={props.handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {!props.showUpdateDatesModal ? "Publish Draft" : "Update Dates"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="baseToken_style">
            <label>Do you want to whitelist users? </label>
            <div className="d-flex align-items-center mb-2">
              <div className="fldWrap customRadio me-2">
                <input
                  type="radio"
                  onChange={handleWhitelistChange}
                  name="isWhitelist"
                  id="test1"
                  checked={isWhiteList === "yes"}
                  value={"yes"}
                />{" "}
                <label for="test1"> Yes</label>
              </div>
              <div className="fldWrap customRadio me-2">
                <input
                  type="radio"
                  id="test2"
                  onChange={handleWhitelistChange}
                  name="isWhitelist"
                  checked={isWhiteList === "no"}
                  value={"no"}
                />{" "}
                <label for="test2">No</label>
              </div>
            </div>

            {isWhiteList == "yes" ? (
              <Row>
                {Object.values(COUNTDOWN_TYPES).map((item, index) => {
                  return Object.values(item).map((x, y) => (
                    <Col md={6} lg={6}>
                      <div className="mb-2">
                        <label>{x.select_placeholder}</label>
                        <Flatpickr
                          name="date"
                          className="form-control"
                          data-enable-time
                          value={currentDate}
                          placeholder="MM-DD-YYYY"
                          options={{
                            dateFormat: "d/m/Y H:i",
                            minuteIncrement: 1,
                          }}
                          onChange={([date]) => {
                            setTime(index, y, date);
                          }}
                        />
                      </div>
                    </Col>
                  ));
                })}
              </Row>
            ) : (
              <Row>
                {Object.values(COUNTDOWN_TYPES.postsale).map((type, index) => (
                  <Col md={6} lg={6}>
                    <div className="mb-2">
                      <label>{type.select_placeholder}</label>
                      <Flatpickr
                        name="date"
                        className="form-control"
                        data-enable-time
                        value={currentDate}
                        options={{
                          dateFormat: "d/m/Y H:i",
                          minuteIncrement: 1,
                        }}
                        placeholder="MM-DD-YYYY"
                        onChange={([date]) => {
                          setTime(2, index, date);
                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            )}

            {/* main sale time */}
          </Col>
        </Row>
        {/* private minting  */}
        {!props.showUpdateDatesModal && (
          <Row>
            <PrivateMinting
              supply={props.supply}
              getPrivateMintData={handlePrivateMinting}
            />
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cmnBtn"
          onClick={() => handleResponse(true, countdownTimes, isWhiteList)}
        >
          Yes
        </Button>
        <Button className="cmnBtn" onClick={() => handleResponse(false)}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReleaseDateConfirmationModal;
