import iconMatamask from "../assets/images/metamask_icon.png";
import iconWallet from "../assets/images/wallet_icon.svg";

const API_URL = process.env.REACT_APP_API_URL;
const DOMAIN = process.env.REACT_APP_DOMAIN;
const INFURA_ID = process.env.REACT_APP_INFURA_ID
const COLLECTION_TYPES = Object.freeze({
  DRAFT: "DRAFT",
  LIVE: "LIVE",
});

const NFT_LIMITS = {
  UPLOAD_LIMIT: 10000, // file upload limit
  FILE_SIZE_LIMIT: 10, // in mb
};

const COUNTDOWN_TYPES = {
  whitelist: {
    start_time: {
      time: 0,
      text: "whitelisting will start in",
      select_placeholder:"Select whitelist start time",
      status:"Minting closed"
    },
    end_time: {
      time: 0,
      text: "whitelisting ends in",
      select_placeholder:"Select whitelist end time",
      status:"Whitelisting active"
    }
  },

  presale: {
    start_time: {
      time: 0,
      text: "pre-sale will start in",
      select_placeholder:"Select pre-sale start time",
      status:"Whitelisting ended"
    },
    end_time: {
      time: 0,
      text: "pre-sale ends in",
      select_placeholder:"Select pre-sale end time",
      status:"Pre-Sale active"
    },
  },
  
  postsale: {
   start_time:{
    time: 0,
    text: "main-sale will start in",
    select_placeholder:"Select main-sale start time",
    status:"Pre-Sale ended"
   },
   end_time:{
    time: 0,
    text: "main-sale ends in",
    select_placeholder:"Select main-sale end time",
    status:"Main-Sale active"
   }
  },
};

const PUBLISH_DATES = {
  whitelist:{
    start_time:new Date(),
    end_time:new Date()
  },
  presale:{
    start_time:new Date(),
    end_time:new Date(),
  },
  mainsale:{
    start_time:new Date(),
    end_time:new Date(),
  },
}

const PUBLISH_PARAMS = {
  releaseDate:"",
  mainSaleStartsIn:"",
  isWhiteListedEnable:"",
  preSaleEndTime:"",
  mainSaleEndsIn:"",
  preSaleStartTime:"",
  whiteListEndTime:""
}

const MINTING_TYPES = {
  private:"PRIVATE",
  public:"PUBLIC"
}

const INVITATION_TYPES = {
    private:"PRIVATE",
    public:"PUBLIC"
}

const WALLETS = [
  {
    type:"METAMASK",
    text:"MetaMask",
    image:iconMatamask
  },
  {
    type:"WALLETCONNECT",
    text:"WalletConnect",
    image:iconWallet
  }
]

const WALLET_TYPES = {
  metamask: "METAMASK",
  walletConnect:"WALLETCONNECT"
}

const WC_RPC = {
  4: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
  80001:`https://rpc-mumbai.maticvigil.com/`,
  97:`https://data-seed-prebsc-1-s1.binance.org:8545/`
};

const QR_CODE_LINKS = {
  mobileLinks: [
    // "rainbow",
    "metamask",
    // "argent",
    // "trust",
    // "imtoken",
    // "pillar",
  ],
  desktopLinks: ["encrypted ink"],
}

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

export const Enviroments = {
  API_URL,
  COLLECTION_TYPES,
  NFT_LIMITS,
  COUNTDOWN_TYPES,
  PUBLISH_DATES,
  PUBLISH_PARAMS,
  DOMAIN,
  MINTING_TYPES,
  INVITATION_TYPES,
  INFURA_ID,
  WALLETS,
  WALLET_TYPES,
  WC_RPC,
  QR_CODE_LINKS,
  ENCRYPTION_KEY
};
