import React from "react";
import "./Sidebar.scss";
// import { rootName } from "../../constant";
// import logo from "../../assets/images/logo.png";
import { Link, NavLink } from "react-router-dom";
import "../../pages/private/Setting/Setting";

const Sidebar = (props) => {
  return (
    <div className="sidebar">
      <ul className="linkList">
        <li activeClassName="is-active">
          <NavLink to={`/auth/mainsetting`} >
            Change Password
          </NavLink>
        </li>

        {/* <li activeClassName="is-active">
          <NavLink to={`/auth/collection/allCollection`} >
            Back
          </NavLink>
        </li> */}
       
      </ul>
    </div>
  );
};

export default Sidebar;
