import React, { useEffect, useState } from "react";
import { Enviroments } from "../../../constants/constants";
import moment from "moment";
import "./CountdownTimer.scss";

function CountdownTimer({
  collectionDetails,
  getCustomizedColor,
  setDisableMinting,
}) {
  let countDownWatcher = localStorage.getItem("timer");
  const [countdown, setCountdown] = useState({
    text: "",
    timer: "00:00:00:00",
    status: "Minting Closed",
  });

  const { COUNTDOWN_TYPES, PUBLISH_PARAMS } = Enviroments;

  const launchTimer = ({ time: timestamp, status, text }) => {
    let countDownDate = timestamp;

    if (!isNaN(countDownDate)) {
      countDownWatcher = setInterval(() => {
        const now = new Date().getTime();

        const distance = countDownDate - now;

        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (days < 10) days = `0${days}`;
        if (hours < 10) hours = `0${hours}`;
        if (minutes < 10) minutes = `0${minutes}`;
        if (seconds < 10) seconds = `0${seconds}`;
        const time = `${days}:${hours}:${minutes}:${seconds}`;
        setCountdown((count) => {
          return {
            ...count,
            timer: time,
            status: status,
            text: text,
          };
        });

        if (Number(distance) < 0) {
          clearInterval(countDownWatcher);
          setCountdown({
            text: "",
            timer: "00:00:00:00",
            status: status,
          });
          handleCountDownTimer();
        }
      }, 1000);
      localStorage.setItem("timer", countDownWatcher);
    }
  };

  const handleCountDownTimer = () => {
    setDisableMinting(false);

    window.clearInterval(countDownWatcher);

    const {
      releaseDate,
      whiteListEndTime,
      preSaleStartTime,
      preSaleEndTime,
      mainSaleStartsIn,
      mainSaleEndsIn,
    } = collectionDetails;
    let { whitelist, presale, postsale } = COUNTDOWN_TYPES;
    let datesArr = [],
      currentElemPos = -1,
      blockers = [];

    const whiteListDates = {
      releaseDate,
      whiteListEndTime,
      preSaleStartTime,
      preSaleEndTime,
    };

    if (collectionDetails.isWhiteListedEnable) {
      // initialize dates for timers
      const dates = {
        releaseDate,
        whiteListEndTime,
        preSaleStartTime,
        preSaleEndTime,
        mainSaleStartsIn,
        mainSaleEndsIn,
      };
      let timers = [{ whitelist }, { presale }, { postsale }];

      let new_dates = [
        toLocalDateTime(releaseDate),
        toLocalDateTime(whiteListEndTime),
        toLocalDateTime(preSaleStartTime),
        toLocalDateTime(preSaleEndTime),
        toLocalDateTime(mainSaleStartsIn),
        toLocalDateTime(mainSaleEndsIn),
      ];
      let new_timers = [...timers];

      //sort time on timestamp
      datesArr = sortTimers(new_timers, new_dates);

      //find position of current time and run timers
      currentElemPos = datesArr.findIndex((x) => x.label == "current");
      runTimers(currentElemPos, datesArr);

      // disable minting on particular time
      blockers = [0, 1, 2, 4, datesArr.length - 1];
      blockMinting(blockers, currentElemPos);
    } else {
      // initialize dates for timers
      const dates = { mainSaleEndsIn, mainSaleStartsIn };
      if (checkMainSaleOnlyOrNot(whiteListDates))
        postsale.start_time.status = "Minting Closed";
      let timers = [{ postsale }];

      let new_dates = [
        toLocalDateTime(mainSaleStartsIn),
        toLocalDateTime(mainSaleEndsIn),
      ];
      let new_timers = [...timers];

      //sort time on timestamp
      datesArr = sortTimers(new_timers, new_dates);

      //find position of current time and run timers
      currentElemPos = datesArr.findIndex((x) => x.label == "current");
      runTimers(currentElemPos, datesArr);

      // disable minting on particular time
      blockers = [0, datesArr.length - 1];
      blockMinting(blockers, currentElemPos);
    }
    // show minting closed status
    if (currentElemPos == 0 || currentElemPos == datesArr.length - 1) {
      setCountdown((count) => {
        return {
          ...count,
          status: "Minting Closed",
        };
      });
    }
  };

  const sortTimers = (new_timers, new_dates) => {
    let datesArr = [],
      modified_timers = [];
    let currentTime = {
      time: new Date().getTime(),
      label: "current",
    };

    modified_timers = [...new_timers];
    modified_timers.forEach((timer) => {
      for (const [key, value] of Object.entries(timer)) {
        Object.values(value).forEach((val) => {
          val.label = key;
        });
      }
    });

    modified_timers.forEach((timer, index) => {
      Object.values(timer).forEach((x) => {
        x.start_time.index = 2 * index + 1;
        x.end_time.index = 2 * index + 2;
      });
    });

    modified_timers = modified_timers
      .map((time) => {
        return Object.values(time).map((x) => x);
      })
      .flat();

    let modified_timers_start_time = modified_timers.map((time) => {
      return time.start_time;
    });

    let modified_timers_end_time = modified_timers.map((time) => {
      return time.end_time;
    });

    modified_timers = modified_timers_start_time
      .concat(modified_timers_end_time)
      .sort((x, y) => x.index - y.index);

    modified_timers.forEach((timer, index) => {
      timer.time = convertToTime(new_dates[index]);
    });

    modified_timers.push(currentTime);

    datesArr = modified_timers.sort((a, b) => Number(a.time) - Number(b.time));
    // console.log(
    //   "sortarray in dates",
    //   datesArr.map((x) => {
    //     return {
    //       ...x,
    //       time: moment(x.time).format("DD-MM-YYYY HH:mm:ss"),
    //     };
    //   })
    // );
    return datesArr;
  };

  const runTimers = (currentElemPos, datesArr) => {
    // run timers
    currentElemPos = datesArr.findIndex((x) => x.label == "current");
    if (currentElemPos < datesArr.length - 1) {
      setCountdown({
        text: datesArr[currentElemPos + 1].text,
      });
      launchTimer(datesArr[currentElemPos + 1]);
    }
  };

  const blockMinting = (blockers, currentElemPos) => {
    if (blockers.includes(currentElemPos)) {
      setDisableMinting(true);
    }
  };

  const convertToTime = (date) => {
    if (!date) return "";
    return new Date(date).getTime();
  };

  const toLocalDateTime = (date) => {
    if (!date) return "";
    return moment(date).local().format();
  };

  const checkMainSaleOnlyOrNot = (data) => {
    return (
      Object.values(data).filter((val) => {
        return !val;
      }).length != 0
    );
  };

  useEffect(() => {
    if (collectionDetails._id) handleCountDownTimer();
  }, [collectionDetails]);

  useEffect(() => {
    return () => {
      clearInterval(countDownWatcher);
    };
  }, []);

  return (
    <>
      <div className="count">
        {collectionDetails.isLive && (
          <>
            {/* <h5
              style={getCustomizedColor("heading")}
              className="d-flex align-items-center mb-4"
            >
              Status:
              <p style={{ margin: "10px" }}>
                {!countdown.status ? "Minting Closed" : countdown.status}
              </p>
            </h5> */}

            {countdown.timer !== "00:00:00:00" && (
              <div className="timer justify-content-center mb-3">
                <h5
                  style={getCustomizedColor("heading")}
                  className=" labeltext"
                >
                  {/* {countdown.text}:{" "} */}
                  {countdown.text} :
                </h5>
                <div className="timerSec ">
                  <h3
                    style={getCustomizedColor("heading")}
                    className="mb-0 ms-2"
                  >
                    {countdown.timer !== "00:00:00:00" && (
                      <>
                        <span
                          className="time"
                          style={getCustomizedColor("inputField")}
                        >
                          {countdown.timer?.split(":").map((x) => (
                            <span style={getCustomizedColor("text")}>{x}</span>
                          ))}
                        </span>

                        <div className="tracker">
                          <span style={getCustomizedColor("text")}>Day</span>
                          <span style={getCustomizedColor("text")}>Hrs</span>
                          <span style={getCustomizedColor("text")}>Min</span>
                          <span style={getCustomizedColor("text")}>Sec</span>
                        </div>
                      </>
                    )}
                  </h3>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default CountdownTimer;
