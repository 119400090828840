import { UrlValidator } from "./urlValidator";

class DiscordValidator extends UrlValidator {
  constructor(url) {
    super(url);
    this.validateDiscordUrl();
  }

  validateDiscordUrl() {
    const regex =
    /(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/;
    const str = this.url;
    this.errors.discord = regex.test(str) ? "" : "Invalid discord url";
  }
}

export { DiscordValidator };








