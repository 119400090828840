import React from "react";
import { Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "../socialmedia/media.scss";
import twitter from "../../../assets/images/Tweeter.svg";
import instagram from "../../../assets/images/Instagram.svg";
import facebook from "../../../assets/images/facebook.svg";
import { useDispatch } from "react-redux";
import vector from '../../../assets/images/Vector.svg'

const SocialMediaIcons = ({ match, customize }) => {
  let {
    params: { externalLink },
  } = match;
  const dispatch = useDispatch();

  return (
    <ul className="media d-flex mb-0 ps-0">
      {customize.socialMedia.twitter && (
        <li>
          <a href={customize.socialMedia.twitter} target="_blank">
            <Image src={twitter} alt="icon" />
          </a>
        </li>
      )}

      {/* <li>
        <Link to="">
          <Image src={Medium} alt="icon" />
        </Link>
      </li> */}
      {customize.socialMedia.instagram && (
        <li>
          <a href={customize.socialMedia.instagram} target="_blank">
            <Image src={instagram} alt="icon" />
          </a>
        </li>
      )}

      {customize.socialMedia.facebook && (
        <li>
          <a href={customize.socialMedia.facebook} target="_blank">
            <Image src={facebook} alt="icon" />
          </a>
        </li>
      )}
      {customize.socialMedia.discord && (
        <li>
          <a href={customize.socialMedia.discord} target="_blank">
            <Image src={vector} alt="icon" />
          </a>
        </li>
      )}
    </ul>
  );
};

export default withRouter(SocialMediaIcons);
