const types = {
    persist:{
        SAVE_WALLET_ADDRESS: "[REDUCER] SAVE_WALLET_ADDRESS",
        SAVE_IS_LOGGED_IN: "[REDUCER] SAVE_IS_LOGGED_IN",
        SAVE_JWT_TOKEN: "[REDUCER] SAVE_JWT_TOKEN",
        RESET_STORE: "[REDUCER] RESET_STORE",
        SAVE_USER: "[REDUCER] SAVE_USER"
    },
    loading:{
        SAVE_GET_LOADING:"[REDUCER] SAVE_GET_LOADING"
    },
    collection:{
        SAVE_COLLECTION:"[REDUCER] SAVE_COLLECTION",
        ALLOW_NFT_CREATION: "[REDUCER] ALLOW_NFT_CREATION",
        SAVE_COLLECTION_WALLET_ADDRESS: "[REDUCER] SAVE_COLLECTION_WALLET_ADDRESS",
        SAVE_NFT_UPLOAD: "[REDUCER] SAVE_NFT_UPLOAD",
        SAVE_WALLET_WHITELIST_STATUS:"[REDUCER] SAVE_WALLET_WHITELIST_STATUS",
        SAVE_NFT_PAGE:"[REDUCER] SAVE_NFT_PAGE",
        SAVE_COLLECTION_UPDATED_STATUS:"[REDUCER] SAVE_COLLECTION_UPDATED_STATUS",
        SAVE_WALLET_TYPE: "[REDUCER] SAVE_WALLET_TYPE"
    },
    admin:{
        GET_COMMISSION:"[REDUCER] GET_COMMISSION"
    }

}

export default types;