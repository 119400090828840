import React from "react";
import {Card } from "react-bootstrap";
import "./Card.scss";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

function Cards({history}) {
  const [collections, setCollections] = useState({
    list:[],
    totalCounts:0
  });

  const dispatch = useDispatch();

  const getAllCollectionsHomePage = async() => {
    const data = {
      page:0,
      limit:4
    }
    try {
     let res = await dispatch(CollectionActions.getAllCollectionsHomePage(data));
     console.log(res);
     if(res.data.data && Object.keys(res.data.data).length > 0){
      setCollections(res.data.data)
     }
    } catch (error) {
      console.log(error)
    }
  }

  const getCoinImage = (coin) => {
    if(coin==="bsc") coin = "bnb"
    return <img src={require(`../../../assets/images/${coin}.svg`).default} />
  }

  const goToMintingPage = (data) => {
    const {blockChain:{blockChain},externalLink} = data;
    if(blockChain)
    history.push(`/${blockChain.toLowerCase()}?collection=${externalLink}`)
  }

  useEffect(()=>{
    getAllCollectionsHomePage()
  },[])

  return (
    <>
      <div className="cardBlocks">
        <Row>
          {collections?.list.map((user) => (
            <Col xl={3} lg={4} md={6} sm={12} className="card_link" onClick={()=>goToMintingPage(user)}>
              <Card className="cards ">
                <Card.Img className="mainImg" src={user.logo} />
                <Card.Body className="content">
                  <div>
                    <Card.Title>{user.name}</Card.Title>
                    <Card.Text>{user.description}</Card.Text>
                  </div>
                  <div className="coin_image">
                    {
                      getCoinImage(user.blockChain?.blockChain.toLowerCase())
                    }
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default withRouter(Cards);
