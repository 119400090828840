import React from "react";
import { BallTriangle } from "react-loader-spinner";
import "./Loader.scss";

function Loader(props) {
  return (
    <>
      {props.isLoading ? (
        <div className="loaderOverlay">
          <BallTriangle color="#5859EB" height={80} width={80} />
        </div>
      ) : null}
    </>
  );
}

export default Loader;
