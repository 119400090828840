import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { toasts as toast } from "../Toast/Toast";
import "./GenerateInvitationCode.scss";
import {Enviroments} from '../../../constants/constants'

function GenerateInvitationCode({ getInvitations, collectionDetails }) {
  const {INVITATION_TYPES} = Enviroments
  const [invitationData, setInvitationData] = useState({
    maxNumberOfWallet:0,
    type:INVITATION_TYPES.private
  })
  const dispatch = useDispatch();

  const selectNumber = (e) => {
    setInvitationData((data)=> {
      return {
        ...data,
        maxNumberOfWallet:e.target.value
      }
    });
  };

  const getInvitationCode = async (data) => {
    try {
      const { getInvitationCode } = CollectionActions;
      await dispatch(
        getInvitationCode({
          ...data,
          collectionId: collectionDetails._id,
        })
      );
      setInvitationData((data)=>{
        return {
          ...data,
          maxNumberOfWallet:0
        }
      });
      getInvitations({page:0,limit:10});
    } catch (error) {
      
    }
  };

  const handleSubmit = (e) => {
    if (!invitationData.maxNumberOfWallet) {
      toast.error("Please enter seats");
      return;
    }

    if (invitationData.maxNumberOfWallet < 1) {
      toast.error("Seats can't be less than 0");
      return;
    }
    if(invitationData.maxNumberOfWallet != parseInt(invitationData.maxNumberOfWallet)){
      toast.error("Seats can be integer only");
      return;
    }
    getInvitationCode(invitationData);
  };

  const handleRadioClick = (e) => {
    setInvitationData((data)=>{
      return {
        ...data,
        type:e.target.value
      }
    })
  }

  return (
    <div className="my-3 invitation_container">
      <Row>
        <Col className="baseToken_style">
          <label>No. of Wallets</label> &nbsp;
          <input type="number" value={invitationData.maxNumberOfWallet} onChange={selectNumber} />
          <span className="mx-3">
          <Form.Check
            inline
            label="Private Link"
            name="group1"
            type="radio"
            onClick={handleRadioClick}
            value={INVITATION_TYPES.private}
            checked={invitationData.type===INVITATION_TYPES.private}
            id={`inline-radio-1`}
          />
          <Form.Check
            inline
            label="Public Link"
            name="group1"
            type="radio"
            value={INVITATION_TYPES.public}
            checked={invitationData.type===INVITATION_TYPES.public}
            onClick={handleRadioClick}
            id={`inline-radio-2`}
          />
          </span>
          
          <Button className="ms-2 cmnBtn" variant="primary" onClick={handleSubmit}>
            Generate
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default GenerateInvitationCode;
