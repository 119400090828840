import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Pagination from "react-js-pagination";

import { Footer, Layout } from "../../../common";
import Cards from "../../../common/card/Cards";
import Header from "../../../common/header/Header";
import LeftHeaderSection from "../../../common/LeftHeaderSection/LeftHeaderSection";
import Slider from "../../../common/slider/Slider";
import Faq from "../faq/Faq";
import "./landing.scss";

function Landing() {
  return (
    <>
      <div className="landing-page">
        <Header />
        <section className="">
          <Container>
            <Row className="launchpad-Sec">
              {/* <Col lg={8} className="titleSec">
                <h2 className="maintext">SPOZZ.club</h2>
                <h2 className="subtext">NFT Collections Launchpad</h2>
                <div className="gline mb24" />
                <p>
                  Create your own NFT collections: easily, secure and without
                  coding!
                  <br />
                  <p className="my-2">
                    Sell your collection to your community through a
                    personalized minting page!
                  </p>
                  <p>
                    Use advanced features such as whitelisting, private minting
                    and more!
                  </p>
                </p>   <div className="text-center mb-4">
          <Image className="nftImage" src={collectionDetails.logo} />
          <div>
            <Button className="frame">
              <Image className="imgSec" src={frame} /> <span /> MINTING TECH.
              provided by SPOZZ.Club
            </Button>
          </div>
        </div>   <div className="text-center mb-4">
          <Image className="nftImage" src={collectionDetails.logo} />
          <div>
            <Button className="frame">
              <Image className="imgSec" src={frame} /> <span /> MINTING TECH.
              provided by SPOZZ.Club
            </Button>
          </div>
        </div>
              </Col> */}
              <LeftHeaderSection />
              <Col lg={4}>
                <Slider />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="cardSec">
          <Container>
            <h2 className="maintitle mb-4 ">NFT collections - minting now:</h2>
            <Cards />
          </Container>
        </section>
        <section className="accordianBg">
          <Container>
            <Faq />
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Landing;
