import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { CollectionActions } from "../../../../redux/actions/collection.action";
import { Layout } from "../../../common";
import Nft from "../../../common/Nft/Nft";
import NftAttributes from "../../../common/NftAttributes/NftAttributes";

function NftDetails({ match, history }) {
  const {
    params: { collectionId, nftId },
  } = match;
  const [nfts, setNfts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [nftDetails, setNftDetails] = useState({});

  const dispatch = useDispatch();

  const getCollectionNfts = async (obj) => {
    const { getCollectionNfts } = CollectionActions;
    let data = {
      ...obj,
      collection: collectionId,
      nftId
    };
    try {
      let res = await dispatch(getCollectionNfts(data));
      setNfts(res.data.data.list);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {
      // console.log(error);
    }
  };
  const getNftDetails = async () => {
    const { getNftDetails } = CollectionActions;

    try {
      let res = await dispatch(getNftDetails(nftId));
      setNftDetails(res.data.data);
    } catch (error) {
      // console.log(error);
    }
  };



  useEffect(()=>{
    const obj = {
      limit: 10,
      page: 0,
      filters: {},
    };
    
    getCollectionNfts(obj);
    getNftDetails();
  },[nftId])

  return (
    <Layout>
      <Container>
        {nftDetails && Object.keys(nftDetails).length > 0 && (
          <Row xs={1} md={2} lg={2} className="mb-5">
            <Col className="collectionImg">
              <Image fluid src={nftDetails.url} />
            </Col>
            {nftDetails.areAttributesUploaded ? (
              <NftAttributes attributes={nftDetails.nftAttributes.attributes} />
            ) : (
              <p>No attributes uploaded</p>
            )}
          </Row>
        )}

        <Nft
          nfts={nfts}
          getNfts={getCollectionNfts}
          totalCount={totalCount}
          collectionId={collectionId}
          parent="nftDetails"
        />
      </Container>
    </Layout>
  );
}

export default withRouter(NftDetails);
