import React from "react";
import CustomInput from "../CustomInput/CustomInput";
import "./SocialMediaCustomization.scss";
import { useState } from "react";
import { validators } from "../../../services/social-media validators";
import { useEffect } from "react";
import { change, Field, Form, reduxForm } from "redux-form";
import { FormField } from "../FormField.jsx";
import { reduxFormHelper } from "../../../HOC/redux.form.helper";
import { validateForm } from "redux-form-validators";
import {
  discordUrlValidator,
  facebookUrlValidator,
  instagramUrlValidator,
  twitterUrlValidator,
} from "../../../validators/customValidators";

function SocialMediaCustomization({
  customize,
  setCustomize,
  setIsCustomized,
  valid,
  change
}) {
  const [errors, setErrors] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
  });

  const handleChange = (e) => {
    setCustomize((obj) => {
      return {
        ...obj,
        socialMedia: {
          ...obj.socialMedia,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  useEffect(()=>{
    
    let count=0;
    Object.values(customize.socialMedia).forEach(val=>{
      Object.keys(customize.socialMedia).forEach((key)=>{
        change(key,customize.socialMedia[key])
      })
    })
  
     
     
    
  },[customize])

  return (
    <section className="Sec__second">
      <h5 className="subTitle">Social Media</h5>
      <p>Add your social networks.</p>

      <div className="mb-2">
        <label>Twitter</label>
        <Field
          className="sidebarInputs inputSec"
          type="text"
          component={FormField}
          onChange={handleChange}
          name="twitter"
          value={customize.socialMedia.twitter}
          placeholder="URL Twitter"
        />
      </div>
      <div className="mb-2">
        <label>Instagram</label>
        <Field
          className="sidebarInputs inputSec"
          type="text"
          component={FormField}
          onChange={handleChange}
          name="instagram"
          value={customize.socialMedia.instagram}
          placeholder="URL Instagram"
        />
      </div>

      <div className="mb-2">
        <label>Facebook</label>
        <Field
          className="sidebarInputs inputSec"
          type="text"
          component={FormField}
          onChange={handleChange}
          name="facebook"
          value={customize.socialMedia.facebook}
          placeholder="URL Facebook"
        />
      </div>

      <div className="mb-2">
        <label>Discord</label>
        <Field
          className="sidebarInputs inputSec"
          type="text"
          component={FormField}
          onChange={handleChange}
          name="discord"
          value={customize.socialMedia.discord}
          placeholder="URL Discord"
        />
      </div>
    </section>
  );
}

let fields = ["instagram", "facebook", "twitter"];

const validate = validateForm({
  instagram: [instagramUrlValidator()],
  twitter: [twitterUrlValidator()],
  facebook: [facebookUrlValidator()],
  discord: [discordUrlValidator()]
});

let SocialMediaCustomizationForm = reduxFormHelper({
  form: "SocialMediaCustomizationForm",
  component: SocialMediaCustomization,
  fields,
  validate,
});

export default SocialMediaCustomizationForm;
