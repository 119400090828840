import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import { Button, FormControl, InputGroup, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Enviroments } from "../../../constants/constants";
import { AdminActions } from "../../../redux/actions/admin.action";
import { CollectionActions } from "../../../redux/actions/collection.action";
import web3Service from "../../../services/web3.service";
import { toasts as toast } from "../Toast/Toast";
import useQuery from "../../../hooks/useQuery";
import "./Mint.scss";
import upArrow from "../../../assets/images/upArrow.svg";
import downArrow from "../../../assets/images/DownArrow.svg";
import MintingNote from "../MintingNote/MintingNote";
import { useCallback } from "react";
import TermsAndConditionsModal from "../TermsAndConditionsModal/TermsAndConditionsModal";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import { EncryptionHelper } from "../../../utils/EncryptionHelper";
function Mint({
  collectionDetails,
  getCustomizedColor,
  walletAddress,
  connectWallet,
  disableMinting,
  contractFetch,
  mintingType,
  nfts,
  isPrivate,
}) {
  const collection = useSelector((state) => state.collection.collections);
  const [mintValue, setMintValue] = useState(1); // which a user is selecting to mint
  const [mintedCount, setMintedCount] = useState(0); // total nfts minted
  const [mintedCountPerWallet, setMintedCountPerWallet] = useState(0); // mint per wallet
  const [adminCommission, setAdminCommission] = useState({
    commission: 0,
    adminAddress: "",
  });

  const [mintDisabled, setMintDisabled] = useState(disableMinting);

  const dispatch = useDispatch();

  const [isCreator, setIsCreator] = useState(null);

  const preSaleDate = collectionDetails.whiteListEndTime;
  const [totalPrivateNftsLeft, setTotalPrivateNftsLeft] = useState(0); // available private nft
  const [totalPrivateNfts, setTotalPrivateNfts] = useState(0); // total private nft
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [totalNftToMint, setTotalNftToMint] = useState(0); // for public or private minting
  const [availableNfts, setAvailableNfts] = useState(0);

  const allowNumber = (event) => {
    if (isNaN(Number(event.target.value))) {
      return;
    } else {
      setMintValue(event.target.value);
    }
  };

  const checkMaxMintValue = () => {
    const { mintPerWallet, supply } = collectionDetails;
    let maxMintValue = 0;
    if (!collectionDetails.isPrivateMintingAllowed) {
      // for public minting
      setTotalNftToMint(supply);
      maxMintValue = mintPerWallet - mintedCountPerWallet;
    } else {
      // for private minting
      if (isPrivate) {
        // if it's creator interface
        maxMintValue = totalPrivateNftsLeft;
        setTotalNftToMint(totalPrivateNfts);
      } else {
        // if it's not creator interface;
        let publicSupplyCount = 0;
        publicSupplyCount = supply - totalPrivateNfts;
        setTotalNftToMint(publicSupplyCount);
        maxMintValue = mintPerWallet - mintedCountPerWallet;
      }
    }
    console.log({ maxMintValue });
    setAvailableNfts(maxMintValue);
  };

  const setMaxValueOnBlur = () => {
    let leastValue = 1;
    if (availableNfts >= leastValue) {
      if (mintValue > availableNfts) setMintValue(availableNfts);
    } else {
      setMintValue(leastValue);
    }
  };

  const increaseMintValue = () => {
    const { mintPerWallet, supply } = collectionDetails;
    console.log({ mintPerWallet, supply });
    if (mintValue < availableNfts) {
      setMintValue((prevValue) => prevValue + 1);
    }
  };

  const decreaseMintValue = () => {
    if (mintValue > 1) {
      setMintValue((prevValue) => prevValue - 1);
    }
  };

  // gives total available private nft to mint
  const getPrivateNftsSupplyCount = async (id) => {
    try {
      const { getPrivateNftsSupplyCount } = CollectionActions;
      const role = mintingType;
      let res = await dispatch(getPrivateNftsSupplyCount(id));
      let data = res.data.data;
      if (data && Object.keys(data).length > 0) {
        setTotalPrivateNftsLeft(data.leftMintCount);
        setTotalPrivateNfts(data.supply);
      }
    } catch (error) {
      // console.log(error)
    }
  };

  const mintToken = async (e) => {
    e.preventDefault();
    let buyerAddress = "";
    let data = {};
    let contractObj = {};
    try {
      if (!walletAddress) {
        throw new Error("Please connect wallet first");
        buyerAddress = connectWallet(collection.walletAddress.toLowerCase());
        data = {
          limit: mintValue,
          collection: collectionDetails._id,
          walletAddress: buyerAddress,
        };
        contractObj = {
          seller: collectionDetails.walletAddress,
          buyer: buyerAddress,
          commission: adminCommission,
          ...collectionDetails,
        };
      } else {
        data = {
          limit: mintValue,
          collection: collectionDetails._id,
          walletAddress: walletAddress,
        };
        contractObj = {
          seller: collectionDetails.walletAddress,
          buyer: walletAddress,
          commission: adminCommission,
          ...collectionDetails,
        };
      }

      if (mintValue == 0) {
        toast.error("Please select tokens to mint");
      } else if (mintValue <= 100) {
        if (!contractFetch.contract) {
          throw new Error("Contract is still fetching");
        }
        // if(!collectionDetails.contract){
        //   throw new Error('Contract is still fetching');
        // }
        if (
          isPrivate &&
          !!walletAddress &&
          nfts[0].creator.toLowerCase() != walletAddress.toLowerCase()
        ) {
          throw new Error("Only creator can buy");
        }
        const { chainId, blockChain: name } = collectionDetails.blockChain;
        if (mintValue > availableNfts) {
          if (availableNfts == 0) {
            throw new Error(`No NFT's available to mint`);
          }
          throw new Error(`Can't mint more than ${availableNfts}`);
        }
        let res1 = await dispatch(CollectionActions.getInfuraForMint({collectionId:collectionDetails._id}));
        console.log("infura id",res1.data.data.infura);
        let decryptObj = JSON.parse(EncryptionHelper.decrypt(res1.data.data.infura));
        Object.keys(decryptObj).forEach((key)=>{
          decryptObj[key] = JSON.parse(EncryptionHelper.decrypt(decryptObj[key]))
        })
        const infuraId = decryptObj.REACT_APP_INFURA_ID
        await web3Service.changeNetwork({
          walletType: collection.walletType,
          chainId,
          name,
          dispatch,
          infuraId
        });

        
        let res = await dispatch(
          CollectionActions.getNftsForMint(
            data,
            contractObj,
            contractFetch.contract,
            isPrivate
          )
        );

        setMintedCount(mintedCount + mintValue);
        setMintedCountPerWallet(mintedCountPerWallet + mintValue);
      } else {
        toast.error("Can't mint more than 100 tokens");
      }
    } catch (error) {
      console.log(error);
      if (error && error.code == -32602) {
        error.message = "failed!";
      }
      toast.error(error.message);
    }
  };

  // gives mint per wallet
  const getMintedCountPerWallet = async () => {
    try {
      const data = {
        walletAddress: walletAddress.toLowerCase(),
        collectionId: collectionDetails._id,
      };
      let res = await dispatch(CollectionActions.getMintedCountPerWallet(data));
      if (res.data.data) {
        // setMintedCount(res.data.data.mintedCount)
        setMintedCountPerWallet(res.data.data.mintedCount);
      } else {
        setMintedCountPerWallet(0);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const getCommission = async () => {
    try {
      let data = await dispatch(AdminActions.getCommission());
      let { commission, walletAddress } = data;
      setAdminCommission({ commission, adminAddress: walletAddress });
    } catch (error) {
      // console.log(error);
    }
  };

  const isCollectionLauched = () => {
    if (!!preSaleDate) {
      if (new Date().getTime() < new Date(preSaleDate).getTime()) return false;
    }
    return true;
    // if (new Date().getTime() < 164862916150) return true;
  };

  // gives total minted nft
  const getMintedNftsCount = async (id) => {
    try {
      const { getMintedNftsCount } = CollectionActions;
      const role = isPrivate
        ? Enviroments.MINTING_TYPES.private
        : Enviroments.MINTING_TYPES.public;
      let res = await dispatch(getMintedNftsCount(id, role));
      if (res.data.data?.mintedCount) {
        setMintedCount(res.data.data.mintedCount);
      } else {
        setMintedCount(0);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const debugConditions = () => {
    let conditions = {};
    conditions.first =
      isCollectionLauched() && collectionDetails.status === "APPROVED";
    conditions.second =
      mintingType && mintingType == Enviroments.MINTING_TYPES.private;
    console.table(conditions);
  };

  const showMint = useMemo(() => {
    return (
      (isCollectionLauched() && collectionDetails.status === "APPROVED") ||
      (mintingType && mintingType == Enviroments.MINTING_TYPES.private)
    );
  }, [isCollectionLauched(), collectionDetails.status, mintingType]);

  const showConnectWallet = useMemo(() => {
    if (collection.walletType === "" && !isPrivate) {
      if (collectionDetails.isWhiteListedEnable) {
        if (
          new Date().getTime() > new Date(collectionDetails.preSaleStartTime)
        ) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }, [collection, isPrivate]);

  useEffect(() => {
    setIsCreator(
      !!walletAddress &&
        walletAddress === collectionDetails.walletAddress.toLowerCase()
    );
  }, [walletAddress, collectionDetails]);

  useEffect(() => {
    getCommission();
  }, []);

  useEffect(() => {
    if (!walletAddress || !collectionDetails._id) return;
    getMintedCountPerWallet();
    getMintedNftsCount(collectionDetails._id);
    if (mintingType == Enviroments.MINTING_TYPES.private) {
      getPrivateNftsSupplyCount(collectionDetails._id);
    }
    // handleCountDownTimer();
  }, [collectionDetails, walletAddress, mintedCount]);

  useEffect(() => {
    setMintDisabled(disableMinting);
  }, [disableMinting]);

  useEffect(() => {}, [contractFetch]);

  useEffect(() => {
    checkMaxMintValue();
  }, [
    mintedCount,
    walletAddress,
    totalPrivateNfts,
    totalPrivateNftsLeft,
    mintedCountPerWallet,
  ]);

  return (
    <>
      <div className="increase-decrease-count">
        {debugConditions()}
        {/* {showConnectWallet && <ConnectWallet />} */}

        <>
          {showMint && (
            <>
              <Form
                onSubmit={mintToken}
                className="minitingInput d-flex justify-content-center mt-3"
              >
                <InputGroup
                  className={
                    collectionDetails.supply == mintedCount ? "disabled" : null
                  }
                  style={getCustomizedColor("inputField")}
                >
                  <InputGroup.Text
                    style={getCustomizedColor("inputField")}
                    onClick={decreaseMintValue}
                    className="minusplus"
                  >
                    <img src={downArrow} alt="icon" />
                  </InputGroup.Text>
                  <FormControl
                    aria-label="Amount (to the nearest dollar)"
                    type="text"
                    onChange={(e) => allowNumber(e)}
                    value={mintValue}
                    onBlur={setMaxValueOnBlur}
                    style={getCustomizedColor("inputField")}
                  />
                  <InputGroup.Text
                    onClick={increaseMintValue}
                    style={getCustomizedColor("inputField")}
                    className="minusplus"
                  >
                    <img src={upArrow} alt="icon" />
                  </InputGroup.Text>
                </InputGroup>

                <Button
                  disabled={
                    mintDisabled == true ||
                    mintedCount == collectionDetails.supply ||
                    mintedCountPerWallet == collectionDetails.mintPerWallet
                  }
                  className="cmnBtn mintbtn ms-4"
                  type="submit"
                  style={getCustomizedColor("button")}
                >
                  MINT
                </Button>
              </Form>
              <h4 className="m-auto" style={getCustomizedColor("heading")}>
                {mintedCount}/{totalNftToMint}
              </h4>
            </>
          )}

          <MintingNote
            getCustomizedColor={getCustomizedColor}
            collectionDetails={collectionDetails}
            availableNfts={availableNfts}
            isPrivate={isPrivate}
            mintedCount={mintedCount}
          />

          <p style={getCustomizedColor("heading")} className="labeltext">
            With the minting I accept
          </p>
          <a href="javascript:void(0)" onClick={() => setShowTermsModal(true)}>
            <i style={getCustomizedColor("heading")}>Terms & Conditions</i>
          </a>
        </>
      </div>
      <TermsAndConditionsModal
        show={showTermsModal}
        setShowTermsModal={setShowTermsModal}
      />
    </>
  );
}

export default Mint;
