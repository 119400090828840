import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MainCard, Layout } from "../../../common";
// import ConnectWallet from "../../../common/ConnectWallet/ConnectWallet";
import { Field } from "redux-form";
import { required, email } from "redux-form-validators";
import { FormField } from "../../../common/FormField.jsx";
import { matchPasswords } from "../../../../validators/customValidators";
import "./Login.scss";
import { useDispatch } from "react-redux";
import { toasts as toast } from "../../../common/Toast/Toast";
import { UserActions } from "../../../../redux/actions/user.action";
import { reduxFormHelper } from "../../../../HOC/redux.form.helper";
import { useHistory } from "react-router-dom";
import AgreeTerms from "../../../common/AgreeTerms/AgreeTerms";

function Signup(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let { handleSubmit } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isTermsChecked, setisTermsChecked] = useState(false);

  const handleTerms = (value) => {
    setisTermsChecked(value)
  }
  // const walletAddress = useSelector((state) => state.persist.walletAddress);

  const submitForm = async (values) => {
    try {
      const { register } = UserActions;
      const { email, password } = values;
      if(!isTermsChecked){
        throw new Error("Please agree to terms and conditions")
      }
      let data = {
        email,
        password,
      };
      
      let res = await dispatch(register(data));
      toast.success(res.data.message);
      history.push("/login");
    } catch (error) {
      // console.log(error);
      if(error.message){
        toast.error(error.message);
      } else {
        toast.error(error)
      }
    }
  };

  return (
    <Layout>
      <div className="userLogPage">
        <section className="loginSec cmnSec">
          <div className="topSec">
            <Link to="/login" className="logBtn cmnBtn ">
              LOGIN
            </Link>
            <Link to="/signup" className="sgnBt  cmnBtn active">
              REGISTER
            </Link>
          </div>
          <div className="logForm">
            <MainCard>
              <Form
                className="logForm"
                onSubmit={handleSubmit(submitForm)}
                noValidate
              >
                <Form.Group className="fieldWrap" controlId="formBasicEmail">
                  <Field
                    component={FormField}
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    validate={[required(), email()]}
                  />
                  <div className="formIcon">
                    {/* <img src={Email} alt="" /> */}
                  </div>
                  <Form.Text className="text-muted d-none">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="fieldWrap" controlId="formBasicPassword">
                  <Field
                    component={FormField}
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    validate={[required()]}
                  />
                  <div className="formIcon">
                    {/* <img src={Email} alt="" /> */}
                  </div>
                </Form.Group>

                <Form.Group className="fieldWrap" controlId="formBasicPassword">
                  <Field
                    component={FormField}
                    type="password"
                    name="new_password"
                    className="form-control"
                    placeholder="Confirm Password"
                    validate={[required(), matchPasswords()]}
                  />
                  <div className="formIcon">
                    {/* <img src={Phone} alt="" /> */}
                  </div>
                </Form.Group>

                <Form.Group  className="fieldWrap">
                    <AgreeTerms handleTerms={handleTerms}/>
                </Form.Group>

                {/* {!!walletAddress ? (
                  <span>{walletAddress}</span>
                ) : (
                  <Button
                    className="cmnBtn1  cmnBtn"
                    type="button"
                    onClick={connectWallet}
                  >
                    Connect Wallet
                  </Button>
                )} */}

                <Button className="cmnBtn2  cmnBtn" type="submit">
                  Register
                </Button>
              </Form>
            </MainCard>
          </div>
        </section>
      </div>
    </Layout>
  );
}

const fields = ["email", "password", "new_password"];

let signupForm = reduxFormHelper({
  form: "signup",
  fields,
  component: Signup,
});

export default signupForm;
