import React, { useEffect, useState } from "react";
import { Container, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { CollectionActions } from "../../../../redux/actions/collection.action";
import { Layout } from "../../../common";
import MyCollection from "../../../common/MyCollection/MyCollection";
import Draft from "../../../draft/Draft";
import "./AllCollection.scss";

function AllCollection({ history }) {
  const [key, setKey] = useState("collections");
  const [externalLink, setExternalLink] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (history.location.state) {
      setExternalLink(history.location.state.externalLink)
    }
  }, [history.location])

  useEffect(() => {
    if (!!externalLink) {
      setKey("drafts");
    }
  }, [externalLink]);

  useEffect(()=>{
    dispatch(CollectionActions.saveWalletType({ walletType: ""}));
    localStorage.removeItem("walletconnect");
    dispatch(CollectionActions.saveCollectionWalletAddress({ walletAddress: ""}));
  },[])

  return (
    <Layout>
      <Container className="ContMain allCollection">
        <Row className="banner_row ">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="collections" title="Collections">
              {key == "collections" && <MyCollection />}
            </Tab>
            <Tab eventKey="drafts" title="Drafts">
              {key == "drafts" && <Draft setKey={setKey} />}
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </Layout>
  );
}

export default withRouter(AllCollection);
