import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const SessionGuard = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = rest;
  
  return (
    <Route
      {...rest}
      render={props =>{
        if(!isLoggedIn){
          return <Component {...props} />
        } else {
            return <Redirect
            to={{
              pathname: "/auth/collection/allCollection"
            }}
          />
        
        }
      }
       
        
        
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    isLoggedIn: (state && state.persist && state.persist.isLoggedIn) || false
  };
};

export default connect(
  mapStateToProps,
  null
)(SessionGuard);
