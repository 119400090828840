import isUrl from "validator/lib/isURL";

class UrlValidator {
  isUrlValid = false;
  errors = {
    facebook:"",
    twitter:"",
    instagram:""
  };
  constructor(url) {
    this.url = url;
    this.validateUrl();
  }

  validateUrl() {
    this.isUrlValid = isUrl(this.url, { protocols: ["http", "https"] });
    if(!this.isUrlValid){
        this.errors.facebook = "Invalid facebook url"
        this.errors.instagram = "Invalid instagram url"
        this.errors.twitter = "Invalid twitter url"
    }
  }
}

export { UrlValidator };
