import React from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../../components/common/PageNotFound/PageNotFound";
import { Login } from "../../components/pages/public";

const SessionRoutes = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} exact/>
      <Route path="*" component={PageNotFound}/>
    </Switch>
  );
};

export default SessionRoutes;
