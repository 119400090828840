import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import add_circle from "../../../assets/images/add_circle_plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { CollectionActions } from "../../../redux/actions/collection.action";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import { Enviroments } from "../../../constants/constants";
import "./MyCollection.scss";

function MyCollection({ history }) {
  const [collections, setCollections] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const limit = 3;
  const numberOfPages = Math.ceil(totalCount / limit);
  const {
    COLLECTION_TYPES: { LIVE },
  } = Enviroments;

  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit: limit,
      page: e==0 ? 0 : e-1,
      filters: {},
      type: LIVE,
    };
    // getCollections(obj);
  };

  const getCollections = async (data) => {
    const { getCollections } = CollectionActions;
    try {
      const res = await dispatch(getCollections(data));
      setCollections(res.data.data);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getCollections({ page: page == 0 ? 0 : page-1, limit: limit, filters: {}, type: LIVE });
  }, [page]);

  const goToCollection = (obj) => {
    history.push({
      pathname: `/auth/collection/details/${obj.externalLink}`,
    });
  };

  return (
    <Container className="ContMain myCollection">
      <Row className="banner_row ">
        <Col lg={9}>
          <div className="collection_col">
            <h2 className="main-heading">My Collections </h2>
            <p>
              Create, curate, and manage collections of unique NFTs to share and
              sell.
            </p>
            <p>
              To create a customized minting page for your project, please click on the drafts tab
            </p>
          </div>
        </Col>
      </Row>

      <Row className="mb-5 collectionList">
        <Col>
          <div className="col-new mb-3 ">
            <p className="textStyle mt-auto">Create new collection</p>
            <Button
              className="create-btn mt-auto"
              onClick={() => history.push("/auth/collection/CreateNew")}
            >
              CREATE <img src={add_circle} />
            </Button>
          </div>
        </Col>

        {collections.totalCounts > 0 ? (
          <>
            {collections.list.map((row, i) => (
              <Col style={{ cursor: "pointer" }} key={i}>
                <div
                  className="coll-new col-new mb-3"
                  onClick={() => goToCollection(row)}
                >
                  <Image
                    fluid
                    style={{ height: "150px" }}
                    src={row.logo}
                    alt="name"
                  />
                  <h5>{row.name}</h5>
                </div>
              </Col>
            ))}
          </>
        ) : (
          <p className="mt-2 noCollection offset-4 ">No Collections Found</p>
        )}
      </Row>
      {totalCount > limit && (
        <Row>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalCount}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        </Row>
      )}
    </Container>
  );
}

export default withRouter(MyCollection);
