import React, { useState } from "react";
import copy from "copy-to-clipboard";
import CopyIcon from "../../../assets/images/copy_icon.svg";
import "./CopyToClipboard.scss";
import {Enviroments} from '../../../constants/constants'

function CopyToClipboard({ text, style, refObj }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (val) => {
    copy(val);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  

  const generateInvitationLink = (obj) => {
    let domain = Enviroments.DOMAIN
    return `${domain}/${obj.network}?collection=${obj.externalLink}&code=${obj.inviteCode}`
  }

  const shortLinkCopy = () => {
    let textToCopy  = "";
    if(refObj){
      textToCopy = generateInvitationLink(refObj)
    }
    if(text){
      textToCopy = text
    }
    
    copyToClipboard(textToCopy)
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <p style={style ? style : null}>{text ? text : refObj.inviteCode}</p>
      <img
        onClick={() => {
          shortLinkCopy();
        }}
        src={CopyIcon}
        alt="copy icon"
        className="copyClipboardIcon"
      />
      {copied && <span className="copiedText" style={style ? style : null}>Copied</span>}
    </div>
  );
}

export default CopyToClipboard;
