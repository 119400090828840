import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { Home, Signup, Login } from "../../components/pages/public/";
import CollectionDetails from "../../components/pages/private/CollectionDetails/CollectionDetails";
import Faq from "../../components/pages/public/faq/Faq";
import ResetPassword from "../../components/pages/public/forgot-password/ResetPassword";
import PageNotFound from "../../components/common/PageNotFound/PageNotFound";
import Landing from "../../components/pages/public/landing/landing";

const PublicRoutes = () => {
  return (
    <Switch>
      <Route path="/" component={Landing} exact>
        {/* <Route path="/" component={Home} exact> */}
        {/* <Redirect to="/login" /> */}
      </Route>
      <Route path="/login" component={Login} exact />
      <Route path="/signup" component={Signup} exact />
      <Route path="/forgot-password" component={ResetPassword} exact />
      <Route path="/:network" component={CollectionDetails} exact />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default PublicRoutes;
