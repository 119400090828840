import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FileActions } from "../../../redux/actions/file.action";
import fileValidationService from "../../../services/fileValidation.service";
import { toasts } from "../Toast/Toast";
import "../../pages/private/CollectionDetails/CollectionDetails.scss";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { EncryptionHelper } from "../../../utils/EncryptionHelper";

function Banner({ type, customize, setCustomize, isPrivate, customizePage }) {
  const acceptedfileList = ["image/png", "image/jpeg"];
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    banner1: "",
    banner2: "",
  });

  const [isApplyCustomization, setIsApplyCustomization] = useState(false);

  const [s3Keys, setS3Keys] = useState(null);

  const handleBannerClick = (choice) => {
    if (choice == 1) {
      document.getElementById(`file-upload1`).click();
    } else {
      document.getElementById(`file-upload2`).click();
    }
  };

  const handleBannerChange = (e) => {
    try {
      const { files, name } = e.target;
      resetBanner(name);
      let isImage;

      // validate banner extension
      if (files.length > 0) {
        isImage = fileValidationService.validate(files[0], acceptedfileList);
      }
      if (isImage) {
        // select file, read and display
        var reader = new FileReader();
        reader.onload = function (e) {
          var image = new Image();
          let width = 0,
            height = 0;
          image.onload = function () {
            width = image.naturalWidth;
            height = image.naturalHeight;

            if (width != 1920 && height != 500) {
              localStorage.setItem("imageValid", "false");
              toasts.error("Wrong dimensions");
            } else {
              localStorage.setItem("imageValid", "true");
            }
          };
          image.src = e.target.result;

          setTimeout(() => {
            if (localStorage.getItem("imageValid") == "true") {
              setSelectedFile((f) => {
                return { ...f, [name]: e.target.result, file: files[0] };
              });
            }
          }, 2000);
        };

        reader.readAsDataURL(files[0]);
      }
    } catch (error) {}
  };

  const saveImage = async (name) => {
    let res = await dispatch(FileActions.uploadImageToS3(selectedFile.file, s3Keys));

    setCustomize((obj) => {
      return {
        ...obj,
        images: {
          ...obj.images,
          [name]: {
            ...obj.images[name],
            url: res,
          },
        },
      };
    });

    setSelectedFile({
      file: null,
      banner1: "",
      banner2: "",
    });

    setIsApplyCustomization(true);
  };

  const resetBanner = (name) => {
    setCustomize((obj) => {
      return {
        ...obj,
        images: {
          ...obj.images,
          [name]: {
            ...obj.images[name],
            url: "",
          },
        },
      };
    });
  };

  useEffect(() => {}, [selectedFile, customize]);

  useEffect(() => {
    if (isApplyCustomization) customizePage(customize);
  }, [isApplyCustomization]);


  const getS3Config = async () => {
    try {
      let res = await dispatch(CollectionActions.getService3Aws());
      console.log("s3-response", res);
      let keys = JSON.parse(EncryptionHelper.decrypt(res.data.data.s3));
      if (Object.keys(keys).length == 0) throw new Error("Invalid config");

      Object.keys(keys).forEach((key) => {
        keys[key] = JSON.parse(EncryptionHelper.decrypt(keys[key]));
      });
      console.log(keys);
      setS3Keys({ ...keys });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    getS3Config();
  }, []);

  return (
    <>
      {type == 1 && customize.images.banner1.isShow && (
        <section className="banner">
          <div className="imageBanner">
            <input
              id="file-upload1"
              type="file"
              name="banner1"
              onChange={handleBannerChange}
              accept="image/*"
              style={{ display: "none" }}
            />

            <label className="container-fluid">
              <div className="vertical-center">
                <img
                  src={selectedFile.banner1 || customize.images.banner1.url}
                  alt=""
                />
                {isPrivate && (
                  <div className="bannerContent">
                    {!selectedFile.banner1 || customize.images.banner1.url ? (
                      <span
                        className="btnBanner"
                        onClick={() => handleBannerClick(1)}
                      >
                        Add Image
                      </span>
                    ) : (
                      <span
                        className="btnBanner"
                        onClick={() => saveImage("banner1")}
                      >
                        Save
                      </span>
                    )}

                    {/* <CoinButton buttonText="Add Image" /> */}

                    <p>Image Format (1920 x 500)</p>
                  </div>
                )}
              </div>
            </label>
          </div>
        </section>
      )}

      {type == 2 && customize.images.banner2.isShow && (
        <section className="four banner">
          <div className="imageBanner">
            <input
              id="file-upload2"
              type="file"
              name="banner2"
              onChange={handleBannerChange}
              accept="image/*"
              style={{ display: "none" }}
            />

            <label className="container-fluid">
              <div className="vertical-center">
                <img
                  src={selectedFile.banner2 || customize.images.banner2.url}
                  alt=""
                />
                {isPrivate && (
                  <div className="bannerContent">
                    {!selectedFile.banner2 || customize.images.banner2.url ? (
                      <span
                        className="btnBanner"
                        onClick={() => handleBannerClick(2)}
                      >
                        Add Image
                      </span>
                    ) : (
                      <span
                        className="btnBanner"
                        onClick={() => saveImage("banner2")}
                      >
                        Save
                      </span>
                    )}
                    <p>Image Format (1920 x 500)</p>
                  </div>
                )}
              </div>
            </label>
          </div>
        </section>
      )}
    </>
  );
}

export default Banner;
