import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Field, Form } from "redux-form";
import { required, length, validateForm } from "redux-form-validators";
import { reduxFormHelper } from "../../../HOC/redux.form.helper";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { ApiService } from "../../../services/api.service";
import contractService from "../../../services/contract.service";
import web3Service from "../../../services/web3.service";
import { FormField } from "../FormField";
import { toasts } from "../Toast/Toast";

const TransferNftModal = ({
  show,
  setShow,
  handleSubmit,
  tokenId,
  contract,
  owner,
  change,
  getPrivateMintedNfts,
  collectionId
}) => {
  const dispatch = useDispatch();
  const walletAddress = useSelector(
    (state) => state.collection.collections.walletAddress
  );
  const hideModal = () => {
    setShow(false);
  };

  const transferNft = async (values) => {
    try {
      const data = {
        buyerAddress: values.buyerAddress.toLowerCase(),
        tokenId,
        contract: contract.contractAddress,
        owner: owner.toLowerCase(),
      };
      let accounts = await web3Service.getAccounts()
      if(owner.toLowerCase()!= accounts[0].toLowerCase()){
        throw new Error("Please select owner")
      }
      let res = await dispatch(CollectionActions.transferNft(data, hideModal));
      const obj = {
        limit: 10,
        page: 0,
        collection: collectionId,
      };
  
      getPrivateMintedNfts(obj);
      if (res) hideModal();
    } catch (error) {
      toasts.error(error?.message)
      // console.log(error);
    }
  };

  useEffect(async () => {
    let accounts = await web3Service.getAccounts()
    change("buyerAddress", accounts[0]);
  }, []);

  return (
    <>
      <Modal
        scrollable={true}
        className="connect_wallet modalComn"
        show={show}
        size="md"
        centered
      >
        <Form onSubmit={handleSubmit(transferNft)}>
          <Modal.Header closeButton onClick={hideModal}>
            <Modal.Title>Transfer Nft</Modal.Title>
          </Modal.Header>
          <Modal.Body className="attributesModal">
            <Row>
              <Col className="baseToken_style">
                <div className="text-center">
                  <label htmlFor="">Buyer Address</label>
                  <Field
                    name="buyerAddress"
                    component={FormField}
                    type="text"
                    placeholder="Enter Receiver Address"
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary cmnBtn" type="submit">
              OK
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const fields = ["buyerAddress"];

const validate = validateForm({
  buyerAddress: [required(), length({ min: 42, max: 42 })],
});

let TransferNftForm = reduxFormHelper({
  form: "transferNftForm",
  fields,
  component: TransferNftModal,
  validate,
});

export default TransferNftForm;
