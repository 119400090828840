import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { connect, useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import Loader from "./components/common/Loader/Loader";
import { Route, Switch } from "react-router-dom";
import AuthGuard from "./routes/guards/AuthGuard";
import { CollectionActions } from "./redux/actions/collection.action";
import "bootstrap/dist/css/bootstrap.min.css";

import { PrivateRoutes, PublicRoutes } from "./routes";
import SessionRoutes from "./routes/session/SessionRoutes";
import SessionGuard from "./routes/guards/SessionGuard";
import { history } from "./redux/history";
import packageJson from "../package.json";
import ScrollToTop from "./components/useful/ScrollToTop/ScrollToTop";
import WalletConnect from "./services/walletconnect.service";

<script
  src="https://code.jquery.com/jquery-3.6.0.slim.min.js"
  integrity="sha256-u7e5khyithlIdTpu22PHhENmPcRdFiHRjhAuHcs05RI="
  crossorigin="anonymous"
></script>;

function App({ isLoading }) {
  const dispatch = useDispatch();

  // Function to clear complete cache data
  const clearCacheData = () => {
    if (!localStorage.getItem("REACT_CACHE")) {
      localStorage.clear();
      localStorage.setItem("REACT_CACHE", packageJson.version);
      window.location.reload();
    } else {
      if (packageJson.version != localStorage.getItem("REACT_CACHE")) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        localStorage.clear();
        localStorage.setItem("REACT_CACHE", packageJson.version);
        window.location.reload();
      }
    }
  };

  clearCacheData();

  const removeLogs = () => {
    if (
      process.env.REACT_APP_ENV == "staging" ||
      process.env.REACT_APP_ENV == "production"
    ) {
      window.console.log = function () {};
      window.console.table = function () {};
      window.console.group = function () {};
      window.console.groupEnd = function () {};
      window.console.count = function () {};
    }
  };

  removeLogs();

  const accountChanged = useCallback(async () => {
    window.ethereum.on("accountsChanged", async (accounts) => {
      console.log({ accounts });
      const { saveCollectionWalletAddress, saveWalletType } = CollectionActions;
      if (accounts.length)
        dispatch(
          saveCollectionWalletAddress({
            walletAddress: accounts[0].toLowerCase(),
          })
        );
      else {
        dispatch(saveCollectionWalletAddress({ walletAddress: "" }));
        localStorage.removeItem("walletconnect");
        dispatch(saveWalletType({ walletType: "" }));
      }
      // toast.success("wallet address saved");
    });
  }, [dispatch, history]);

  const networkChanged = useCallback(async () => {
    window.ethereum.on("networkChanged", function () {});
  }, [dispatch]);

  useEffect(async () => {
    if (!window.ethereum) {
      await dispatch(
        CollectionActions.saveCollectionWalletAddress({ walletAddress: "" })
      );
      return;
    }
    accountChanged();
    networkChanged();
  }, [accountChanged, networkChanged]);

  useEffect(() => {
    document.title = `${document.title}  v${packageJson.version}`;
  }, []);

  useEffect(() => {
    const isWalletConnected = localStorage.getItem("walletconnect");
    if (isWalletConnected) WalletConnect.enableWallet({ dispatch });
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <ScrollToTop />
      <Switch>
        <AuthGuard path="/auth" component={PrivateRoutes} />
        <SessionGuard path="/login" component={SessionRoutes} />
        <Route path="/" component={PublicRoutes} />
      </Switch>
    </>
  );
}

const selector = (state) => ({
  isLoading: state.loading.loading,
});

export default connect(selector)(App);
