import React, { useState } from "react";
import ModalInvitationList from "../ModalInvitationList/ModalInvitationList";
import { Button } from "react-bootstrap";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { Enviroments } from "../../../constants/constants";
import { useMemo } from "react";
import AddToWhitelist from "../AddToWhitelistModal/AddToWhitelist";

const InvitationCode = ({ collectionDetails, getCustomizedColor }) => {
  const [value, copy] = useCopyToClipboard(2000);

  const [showInvitationList, setShowInvitationList] = useState(false);
  const [showWhitelistModal, setShowWhitelistModal] = useState(false);

  const mainSaleStartDate = collectionDetails.mainSaleStartsIn;
  const preSaleEndDate = collectionDetails.preSaleEndTime;
  const mainSaleEndDate = collectionDetails.mainSaleEndsIn;
  const isWhiteListedEnable = collectionDetails.isWhiteListedEnable;
  const whitelistStartDate = collectionDetails.releaseDate;
  const whiteListEndDate = collectionDetails.whiteListEndTime;

  const isMainSaleOpened = useMemo(() => {
    if (collectionDetails._id) {
      if (isWhiteListedEnable) {
        if (!!mainSaleStartDate && !!preSaleEndDate) {
          if (
            new Date().getTime() > new Date(preSaleEndDate).getTime() &&
            new Date().getTime() < new Date(mainSaleEndDate).getTime()
          )
            return true;
        }
      } else {
        if (new Date().getTime() < new Date(mainSaleEndDate).getTime())
          return true;
      }
    }

    return false;
  }, [preSaleEndDate, mainSaleStartDate, new Date().getTime()]);

  const isPreSaleEnded = useMemo(() => {
    
    if (collectionDetails._id) {
      if (!!preSaleEndDate) {
        if (new Date().getTime() > new Date(preSaleEndDate).getTime())
          return true;
      }
      return false;
    }
  }, [preSaleEndDate, new Date().getTime()]);

  const beforeWhiteListEnds = useMemo(() => {
    if (collectionDetails._id) {
      if (!!whitelistStartDate && !!whiteListEndDate) {
        if (new Date().getTime() < new Date(whiteListEndDate).getTime())
          return true;
      }
      return false;
    }
  }, [whitelistStartDate, whiteListEndDate, new Date().getTime()]);


  const generateMainSaleLink = () => {
    const domain = Enviroments.DOMAIN;
    const link = `${domain}/${collectionDetails?.blockChain?.blockChain}?collection=${collectionDetails.externalLink}`;
    copy(link);
  };

  return (
    <>
      <div className="mb-2 text-center">
        <>
          {isWhiteListedEnable && beforeWhiteListEnds && (
            <>
              <Button
                className="mb-5 Parchase cmnBtn"
                style={getCustomizedColor('button')}
                onClick={() => {
                  setShowInvitationList(true);
                }}
              >
                Referral Codes
              </Button>

              <Button
                className="mb-5 Parchase cmnBtn ms-3"
                type="button"
                style={getCustomizedColor("button")}
                onClick={() => setShowWhitelistModal(true)}
              >
                Upload Csv
              </Button>
            </>
          )}
        </>

        {isMainSaleOpened && (
          <>
            <Button
              className="Parchase cmnBtn"
              onClick={() => {
                generateMainSaleLink();
              }}
              style={getCustomizedColor('button')}
            >
              copy minting page URL
            </Button>

            <div>
              {value && <span>Copied value: {value.toLowerCase()}</span>}
            </div>
          </>
        )}

        <AddToWhitelist
          collectionId={collectionDetails._id}
          show={showWhitelistModal}
          setShowWhitelistModal={setShowWhitelistModal}
        />

        <ModalInvitationList
          setShow={setShowInvitationList}
          show={showInvitationList}
          collectionDetails={collectionDetails}
        />
      </div>
    </>
  );
};

export default InvitationCode;
