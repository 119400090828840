class FileValidator {
  constructor() {}

  validate(file, types) {
    if(types.length > 0){
      return types.includes(file.type)
    }
    return false
    
  }
}
export default new FileValidator();
