import React from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import TermsAndConditionsModal from "../TermsAndConditionsModal/TermsAndConditionsModal";
import "./AgreeTerms.scss";

function AgreeTerms({ history, handleTerms }) {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

const handleTermsChange = (e) => {
    const {checked} = e.target;
    console.log({checked});

    setTermsChecked(checked);
    handleTerms(checked)
  }

  return (
    <>
      <p className="terms_container">
        {" "}
        <input type="checkbox" value={termsChecked} checked={termsChecked==true} onChange={handleTermsChange}/>
        <span onClick={() => setShowTermsModal(true)}>
          {" "}
          I agree to{" "}
          <a href="javascript:void(0)" className="terms">
            terms and conditions
          </a>
        </span>
      </p>
      <TermsAndConditionsModal show={showTermsModal} setShowTermsModal={setShowTermsModal}/>
    </>
  );
}

export default withRouter(AgreeTerms);
