import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toasts as toast } from "../Toast/Toast";
import { CollectionActions } from "../../../redux/actions/collection.action";
import "./Attributes.scss";
import FileValidator from "../../../services/fileValidation.service";
import S3Service from "../../../services/s3.service";
import { LoadingActions } from "../../../redux/actions/loading.action";
import { EncryptionHelper } from "../../../utils/EncryptionHelper";

const AttributesModal = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const [file, setFile] = useState(null);
  const [s3Keys, setS3Keys] = useState(null);

  const hideModal = () => {
    props.setShow(false);
  };

  const uploadCsv = async () => {
    const { startLoader, stopLoader } = LoadingActions;
    try {
      await dispatch(startLoader())
      const s3Service  = new S3Service(s3Keys)
      let fileRes = await s3Service.uploadFileToS3Bucket(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("collectionId", props.collectionId);
      formData.append("csvLocation",fileRes.Location);
      formData.append("fileName",file.name);
      let data = formData;
      let res = await dispatch(CollectionActions.addAttribute(data));
      toast.success(res.data.message);
      hideModal();
    } catch (error) {
      toast.error(error);
    } finally {
      await dispatch(stopLoader())
    }
  };

  const selectFile = (e) => {
    if (e.target.value.length > 0) {
      let res = FileValidator.validate(e.target.files[0], acceptedfileList);
      if (res) {
        setFile(e.target.files[0]);
      } else {
        setFile(null);
        toast.error("Please select csv file")
      }
    } else {
      setFile(null);
    }
  };

  const acceptedfileList = [
    "text/csv",
    // ".glb",
    // ".gltf"
  ];

  const getS3Config = async () => {
    try {
      let res = await dispatch(CollectionActions.getService3Aws());
      console.log("s3-response", res);
      let keys = JSON.parse(EncryptionHelper.decrypt(res.data.data.s3));
      if (Object.keys(keys).length == 0) throw new Error("Invalid config");

      Object.keys(keys).forEach((key) => {
        keys[key] = JSON.parse(EncryptionHelper.decrypt(keys[key]));
      });
      console.log(keys);
      setS3Keys({ ...keys });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    getS3Config();
  }, []);

  return (
    <Modal
      scrollable={true}
      className="connect_wallet"
      show={props.show}
      size="lg"
      centered
    >
      <Modal.Header closeButton onClick={hideModal}>
        <Modal.Title>Attributes</Modal.Title>
      </Modal.Header>
      <Modal.Body className="attributesModal">
        <Row>
          <Col className="baseToken_style">
            <div className="text-center">
              <h3>Instructions</h3>
            </div>
            <ol>
              <li>Attributes can be max 50</li>
              {/* <li>Attributes can be max 50</li>
              <li>Attributes can be max 50</li>
              <li>Attributes can be max 50</li> */}
            </ol>
          </Col>
        </Row>

        <Row>
          <Col lg={{ offset: 2, size: 8 }}>
            <Row>
              <Col lg={12}>
                <input
                  type="file"
                  onChange={selectFile}
                  accept={acceptedfileList.join(" ")}
                />
              </Col>
            </Row>
            <Link to="/files/sample.csv" target="_blank" download>
              Click to download sample file
            </Link>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary cmnBtn" disabled={!file} onClick={uploadCsv}>
          Upload csv
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(AttributesModal);
