import React, { useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { withRouter } from "react-router-dom";
import "./AccessModal.scss";

const AccessModal = (props) => {
  const {history} = props;
  return (
    <Modal
      scrollable={true}
      className="connect_wallet ss"
      show={props.show}
      size="lg"
      centered
    >
      {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
      <Modal.Body className="accessModal">
        <Row>
          <Col className="baseToken_style">
            <div className="text-center">
              <InfoCircle size={30} className="mb-3" color="blue" />
            </div>
            <p> {props.message}</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>history.push('/')}>Back</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(AccessModal);
