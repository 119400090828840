import "../components/common/layout/LayoutStyle.scss";

const CustomizedLayout =
  (Component) =>
  ({ style, children, className }) => {
    return (
      <>
        <Component style={style} className={className}>{children}</Component>
      </>
    );
  };

export default CustomizedLayout;
