import { UrlValidator } from "./urlValidator";

class InstagramValidator extends UrlValidator {
  constructor(url) {
    super(url);
    this.validateInstagramUrl();
  }

  validateInstagramUrl() {
    const regex =
      /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;
    const str = this.url;
    this.errors.instagram = regex.test(str) ? "" : "Invalid instagram url";
  }
}

export { InstagramValidator };
